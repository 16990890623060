import {
    API_AUTHOR_RESUBMIT_PAPER,
    API_EDITOR_ACCEPT_DOCUMENT,
    API_EDITOR_ADD_COMMENT,
    API_EDITOR_ASSIGN_REVIEWER,
    API_EDITOR_ASSIGN_REVIEWERS,
    API_EDITOR_CLEAR_ABSTRACTS,
    API_EDITOR_CLEAR_PAPERS,
    API_EDITOR_CLEAR_USERS,
    API_EDITOR_COUNT_USERS,
    API_EDITOR_CREATE_FROM_TEMPLATE,
    API_EDITOR_DECLINE_DOCUMENT,
    API_EDITOR_DELETE_ABSTRACT,
    API_EDITOR_DELETE_DOCUMENT,
    API_EDITOR_DELETE_PAPER,
    API_EDITOR_DELETE_TEMPLATE,
    API_EDITOR_DELETE_USER,
    API_EDITOR_DOWNLOAD_ALL_ABSTRACTS,
    API_EDITOR_DOWNLOAD_TEMPLATE,
    API_EDITOR_EDIT_COMMENT,
    API_EDITOR_EXPORT_PARTICIPANTS,
    API_EDITOR_GET_ABSTRACT,
    API_EDITOR_GET_ABSTRACTS_STATS,
    API_EDITOR_GET_ACTIVITIES,
    API_EDITOR_GET_ALL_ABSTRACTS,
    API_EDITOR_GET_ALL_PAPERS,
    API_EDITOR_GET_PAPER,
    API_EDITOR_GET_REVIEWERS, API_EDITOR_GET_REVIEWERS_STATS,
    API_EDITOR_GET_USER,
    API_EDITOR_GET_USER_FEE,
    API_EDITOR_GET_USERS,
    API_EDITOR_GET_USERS_BY_COUNTRY,
    API_EDITOR_GET_USERS_BY_EVENT, API_EDITOR_IMPERSONATE_USER,
    API_EDITOR_INDEX_TEMPLATES,
    API_EDITOR_PUBLISH_ED_COMMENT,
    API_EDITOR_SET_USER_FEE,
    API_EDITOR_SET_USER_ROLE,
    API_EDITOR_TEST_TEMPLATE, API_EDITOR_UPDATE_PAPER,
    API_EDITOR_UPDATE_USER_ADDRESS,
    API_EDITOR_UPDATE_USER_CREDENTIALS,
    API_EDITOR_UPDATE_USER_SURVEY,
    API_EDITOR_UPLOAD_TEMPLATE,
    EDITOR_CHANGE_USERS_LIST_PAGE,
    EDITOR_SET_LAST_EDITED_ABSTRACT_ID,
    EDITOR_SET_LAST_EDITED_USER_ID
} from "../constants/action-types";

export const editorGetAbstracts = (query) => {
    const queryString = Object.entries(query)
        .map(pair => pair.map(encodeURIComponent).join('='))
        .join('&');

    return {
        type: API_EDITOR_GET_ALL_ABSTRACTS,
        payload: {
            request: {
                url: `/editor/abstracts?sort=-createdAt&limit=1000&${queryString}`,
                method: 'get'
            }
        },
    }
};

export const editorGetPapers = () => {
    return {
        type: API_EDITOR_GET_ALL_PAPERS,
        payload: {
            request: {
                url: `/editor/papers`,
                method: 'get'
            }
        },
    }
};

export const editorClearAbstracts = () => {
    return {
        type: API_EDITOR_CLEAR_ABSTRACTS
    }
};

export const editorClearPapers = () => {
    return {
        type: API_EDITOR_CLEAR_PAPERS
    }
};

export const editorGetAbstractById = (id) => {
    return {
        type: API_EDITOR_GET_ABSTRACT,
        payload: {
            request: {
                url: `/editor/abstracts/${id}`,
                method: 'get'
            }
        },
    }
};


export const editorGetPaperById = (id) => {
    return {
        type: API_EDITOR_GET_PAPER,
        payload: {
            request: {
                url: `/editor/papers/${id}`,
                method: 'get'
            }
        },
    }
};


export const editorGetReviewers = () => {
    return {
        type: API_EDITOR_GET_REVIEWERS,
        payload: {
            request: {
                url: `/editor/reviewers`,
                method: 'get'
            }
        },
    }
};

export const editorAssignReviewer = (abstract, reviewer) => {   // abstract and reviewer are IDs only!
    return {
        type: API_EDITOR_ASSIGN_REVIEWER,
        payload: {
            request: {
                url: `/editor/reviewers/assign`,
                method: 'post',
                data: {
                    abstract,
                    reviewer
                }
            }
        },
    }
};

export const editorAssignReviewersToPaper = (paper, reviewers) => {   // abstract and reviewer are IDs only!
    return {
        type: API_EDITOR_ASSIGN_REVIEWERS,
        payload: {
            request: {
                url: `/editor/papers/${paper.id}/reviewers`,
                method: 'put',
                data: {
                    reviewers
                }
            }
        },
    }
};

export const editorGetUsers = (query) => {
    let queryString = '';
    if(query) {
        queryString = Object.entries(query)
            .map(pair => pair.map(encodeURIComponent).join('='))
            .join('&');
    }

    return {
        type: API_EDITOR_GET_USERS,
        payload: {
            request: {
                url: `/users?sort=-createdAt&limit=1000&${queryString}`,
                method: 'get',
            }
        },
    }
};

export const editorClearUsers = () => {
    return {
        type: API_EDITOR_CLEAR_USERS
    }
};

export const editorCountUsers = () => {
    return {
        type: API_EDITOR_COUNT_USERS,
        payload: {
            request: {
                url: `/users/count`,
                method: 'get',
            }
        },
    }
};

export const editorGetUserById = (id) => {
    return {
        type: API_EDITOR_GET_USER,
        payload: {
            request: {
                url: `/users/${id}`,
                method: 'get',
            }
        },
    }
};

export const editorDeleteUser = (user) => {
    return {
        type: API_EDITOR_DELETE_USER,
        payload: {
            request: {
                url: `/users/${user.id}`,
                method: 'delete',
            }
        },
    }
};

export const editorSetUserRole = (user, role) => {
    return (dispatch, getState) => {
        return dispatch({
            type: API_EDITOR_SET_USER_ROLE,
            payload: {
                request: {
                    url: `/users/${user.id}/role`,
                    method: 'put',
                    data: {
                        role
                    }
                }
            },
        }).then(() => dispatch(editorGetUsers()))
    }

};


export const editorGetUserFee = (user) => {
    return {
        type: API_EDITOR_GET_USER_FEE,
        payload: {
            request: {
                url: `/users/${user.id}/fee`,
                method: 'get',
            }
        },
    }
};

export const editorSetUserFee = (user, paid, type) => {
    return (dispatch, getState) => {
        return dispatch({
            type: API_EDITOR_SET_USER_FEE,
            payload: {
                request: {
                    url: `/users/${user.id}/fee`,
                    method: 'put',
                    data: {
                        paid, type
                    }
                }
            }
        }).then(() => dispatch(editorGetUsers()))
    }
};


export const editorDeleteAbstract = (id) => {
    return {
        type: API_EDITOR_DELETE_ABSTRACT,
        payload: {
            request: {
                url: `/editor/abstracts/${id}`,
                method: 'delete',
            },
            id
        },
    }
};

export const editorDeletePaper = (id) => {
    return {
        type: API_EDITOR_DELETE_PAPER,
        payload: {
            request: {
                url: `/editor/papers/${id}`,
                method: 'delete',
            },
            id
        },
    }
};

export const editorRemoveDocument = (url) => {
    return {
        type: API_EDITOR_DELETE_DOCUMENT,
        payload: {
            request: {
                url: url,
                method: 'delete',
            }
        },
    }
};

export const editorChangeUsersListPage = (page) => {
    return {
        type: EDITOR_CHANGE_USERS_LIST_PAGE,
        page
    }
};

export const editorExportParticipants = () => {
    return {
        type: API_EDITOR_EXPORT_PARTICIPANTS,
        payload: {
            request: {
                url: '/users/export',
                method: 'get',
                responseType: 'blob'
            }
        },
    }
};

export const editorGetUsersByCountry = () => {
    return {
        type: API_EDITOR_GET_USERS_BY_COUNTRY,
        payload: {
            request: {
                url: `/users/stats/country`,
                method: 'get',
            }
        },
    }
};

export const editorGetUsersByEvent = () => {
    return {
        type: API_EDITOR_GET_USERS_BY_EVENT,
        payload: {
            request: {
                url: `/users/stats/event`,
                method: 'get',
            }
        },
    }
};

export const editorGetAbstractsStats = () => {
    return {
        type: API_EDITOR_GET_ABSTRACTS_STATS,
        payload: {
            request: {
                url: `/abstracts/stats`,
                method: 'get',
            }
        },
    }
};

export const editorGetReviewersByReviews = () => {
    return {
        type: API_EDITOR_GET_REVIEWERS_STATS,
        payload: {
            request: {
                url: `/reviews/papers/stats`,
                method: 'get',
            }
        },
    }
}

export const editorDownloadAllAbstracts = () => {
    return {
        type: API_EDITOR_DOWNLOAD_ALL_ABSTRACTS,
        payload: {
            request: {
                url: '/abstracts/zip',
                method: 'get',
                responseType: 'blob'
            }
        },
    }
};

export const editorUploadTemplate = (fileInfo, templateName) => {
    let data = new FormData();
    data.append('file', fileInfo);

    return {
        type: API_EDITOR_UPLOAD_TEMPLATE,
        template: templateName,
        payload: {
            request: {
                url: `/settings/templates/${templateName}`,
                method: 'post',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data
            }
        },
    }
};


export const editorDeleteTemplate = (templateName) => {
    return {
        type: API_EDITOR_DELETE_TEMPLATE,
        template: templateName,
        payload: {
            request: {
                url: `/settings/templates/${templateName}`,
                method: 'delete',
            }
        },
    }
};

export const editorIndexTemplates = () => {
    return {
        type: API_EDITOR_INDEX_TEMPLATES,
        payload: {
            request: {
                url: `/settings/templates`,
                method: 'get',
            }
        },
    }
};

export const editorDownloadTemplate = (template) => {
    return {
        type: API_EDITOR_DOWNLOAD_TEMPLATE,
        payload: {
            request: {
                url: `/settings/templates/${template}/download`,
                method: 'get',
                responseType: 'blob'
            }
        },
    }
};

export const editorTestTemplate = (template) => {
    return {
        type: API_EDITOR_TEST_TEMPLATE,
        payload: {
            request: {
                url: `/settings/templates/${template}/test`,
                method: 'get',
                responseType: 'blob'
            }
        },
    }
};

export const editorCreateFromTemplate = (template, data, user) => {
    return {
        type: API_EDITOR_CREATE_FROM_TEMPLATE,
        payload: {
            request: {
                url: `/users/${user.id}/generated-documents/${template}`,
                method: 'post',
                responseType: 'blob',
                data
            }
        },
    }
};

export const editorAcceptGeneratedDocument = (template, user) => {
    return (dispatch, getState) => {
        return dispatch({
            type: API_EDITOR_ACCEPT_DOCUMENT,
            payload: {
                request: {
                    url: `/users/${user.id}/generated-documents/${template}`,
                    method: 'put',
                }
            }
        }).then(() => dispatch(editorGetUserById(user.id)))
    }
};

export const editorDeclineGeneratedDocument = (template, user) => {
    return {
        type: API_EDITOR_DECLINE_DOCUMENT,
        payload: {
            request: {
                url: `/users/${user.id}/generated-documents/${template}`,
                method: 'delete',
            }
        },
    }
};

export const editorGetActivities = () => {
    return {
        type: API_EDITOR_GET_ACTIVITIES,
        payload: {
            request: {
                url: `/activities`,
                method: 'get',
            }
        },
    }
};

export const editorUpdateUserCredentials = (data, userId) => {
    return {
        type: API_EDITOR_UPDATE_USER_CREDENTIALS,
        payload: {
            request: {
                url: `/users/${userId}`,
                method: 'put',
                data: data
            }
        },
    }
}

export const editorUpdateUserSurvey = (data, userId) => {
    return {
        type: API_EDITOR_UPDATE_USER_SURVEY,
        payload: {
            request: {
                url: `/users/${userId}/survey`,
                method: 'put',
                data: data
            }
        },
    }
}

export const editorUpdateUserAddress = (data, userId) => {
    return {
        type: API_EDITOR_UPDATE_USER_ADDRESS,
        payload: {
            request: {
                url: `/users/${userId}/invoice`,
                method: 'put',
                data: data
            }
        },
    }
}

export const editorSetLastEditedUserId = (userId) => {
    return {
        type: EDITOR_SET_LAST_EDITED_USER_ID,
        userId,
    }
}

export const editorSetLastEditedAbstractId = (abstractId) => {
    return {
        type: EDITOR_SET_LAST_EDITED_ABSTRACT_ID,
        abstractId,
    }
}

export const editorAddPaperComment = (paper, data) => {
    let formData = new FormData();
    if(data.file[0])
        formData.append('file', data.file[0].originFileObj);
    formData.append('status', data.status);
    formData.append('comment', data.comment);

    return {
        type: API_EDITOR_ADD_COMMENT,
        payload: {
            request: {
                url: `/editor/papers/${paper.id}/editor-comment`,
                method: 'post',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data: formData
            }
        },
    }
}

export const editorEditPaperComment = (paper, data) => {
    let formData = new FormData();
    if(data.file[0])
        formData.append('file', data.file[0].originFileObj);
    else
        formData.append('file', '');
    formData.append('status', data.status);
    formData.append('comment', data.comment);

    return {
        type: API_EDITOR_EDIT_COMMENT,
        payload: {
            request: {
                url: `/editor/papers/${paper.id}/editor-comment/${data.id}`,
                method: 'put',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data: formData
            }
        },
    }
}

export const editorPublishEditorialComment = (paper, data) => {
    return {
        type: API_EDITOR_PUBLISH_ED_COMMENT,
        payload: {
            request: {
                url: `/editor/papers/${paper.id}/editor-comment/${data.id}/publish`,
                method: 'put',
                data: {
                    published: true
                }
            }
        },
    }
}

export const editorImpersonateUser = (userId) => {
    return {
        type: API_EDITOR_IMPERSONATE_USER,
        payload: {
            request: {
                url: `/users/${userId}/impersonate`,
                method: 'get',
            }
        },
    }
}

export const editorUpdatePaper = (paper, copyright, meta, paperId) => {
    let data = new FormData();
    data.append('paper', paper);
    data.append('copyright', copyright);
    data.append('meta', JSON.stringify(meta))


    return {
        type: API_EDITOR_UPDATE_PAPER,
        payload: {
            request: {
                url: `/editor/papers/${paperId}`,
                method: 'put',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data
            }
        },
    }
}
