import React, {useRef, useState} from 'react';
import {Button, Icon, Input, Modal, Tooltip} from 'antd';
import PropTypes from 'prop-types'
import {Link, withRouter} from "react-router-dom";
import download from 'downloadjs'
import FlagIcon from './Components/FlagIcon'
import {getName} from "country-list";
import cd from "content-disposition";
import {Scrollbar} from 'react-scrollbars-custom';
import styles from './AdminAbstracts.module.scss'
import {StyledTable} from "./Components/StyledTable";
import {NoPaddingCard} from "./Components/NoPaddingCard";
import {Paid} from "./Components/Paid";
import {UserRole} from "./Components/UserRole";
import styled from 'styled-components'
import _ from 'lodash-es'
import FeeStatusForm from "../forms/FeeStatusForm";
import RoleForm from "../forms/RoleForm";
import {useQueryState} from 'react-router-use-location-state'

const AbstractSpan = styled.span`
    width: 70px;
    display: block;
    text-align: center;
`;


const AdminUsers = ({users, getUserFee, setUserFee, setUserRole, exportUsers, setLastId, lastId}) => {
    const [disabledDownloadBtn, setDisabledDownloadBtn] = useState(false);
    // const [dataLoading, setDataLoading] = useState(true);
    const [roleDialogVisible, setRoleDialogVisible] = useState(false);
    const [feeState, setFeeState] = useState({});
    const [editedUser, setEditedUser] = useState({});
    const [feeDialogVisible, setFeeDialogVisible] = useState(false);
    const searchInputRef = useRef(null);
    const feeStatusFormRef = useRef();
    const roleFormRef = useRef();

    const handleExportClick = () => {
        setDisabledDownloadBtn(true);
        exportUsers()
            .then(response => {
                const disposition = cd.parse(response.payload.headers['content-disposition']);
                setDisabledDownloadBtn(false);
                return download(response.payload.data, disposition.parameters.filename);
            })
    };


    const handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    const handleReset = clearFilters => () => {
        clearFilters();
    };

    const renderSearchBox = (dialogTitle) => {
        return ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div className={styles.searchBox}>
                <Input
                    ref={searchInputRef}
                    placeholder={dialogTitle}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={handleSearch(selectedKeys, confirm)}
                />
                <Button type="primary" onClick={handleSearch(selectedKeys, confirm)}>Search</Button>
                <Button onClick={handleReset(clearFilters)}>Reset</Button>
            </div>
        )
    };

    const handleFeeStatusFormOpen = (user) => {
        setFeeDialogVisible(true);
        getUserFee(user).then(({payload: {data}}) => {
            setFeeState({...data, user: user});
        });
    };

    const handleRoleFormOpen = (user) => {
        setEditedUser(user);
        setRoleDialogVisible(true);
    };

    const handleFeeChange = (props) => {
        feeStatusFormRef.current.getForm().validateFields((err, values) => {
            if (err) return;

            setUserFee(feeState.user, values.paid, values.type);
            setFeeDialogVisible(false);

            feeStatusFormRef.current.getForm().resetFields()
        });
    };

    const handleRoleChange = (props) => {
        roleFormRef.current.getForm().validateFields((err, values) => {
            if (err) return;

            setUserRole(editedUser, values.role);
            setRoleDialogVisible(false);
        });
    };

    const handleFeeChangeCancel = () => {
        setFeeDialogVisible(false);
    };


    const handleRoleChangeCancel = () => {
        setRoleDialogVisible(false)
    };

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            width: 60
        },
        {
            title: 'C',
            dataIndex: 'country',
            // key: 'country',
            width: 15,
            sorter: (a, b) => a.country && b.country && a.country.localeCompare(b.country),
            render: (value, record, index) => (
                value && <Tooltip placement="leftTop" mouseLeaveDelay={0} mouseEnterDelay={0} title={getName(value)}>
                    <span>
                    <FlagIcon code={value.toLowerCase()}/>
                    </span>
                </Tooltip>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            // key: 'name',
            filterDropdown: renderSearchBox("Search by name"),
            onFilter: (value, record) => record.firstName.toLowerCase().includes(value.toLowerCase()) || record.lastName.toLowerCase().includes(value.toLowerCase()),
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            render: (value, record, index) => (
                <span key={`name-${record.id}`}>
                    <Link to={`/editor/users/${record.id}`}
                          onClick={() => setLastId(record.id)}>{`${record.firstName} ${record.lastName}`}</Link>
                </span>
            ),
            width: 200,
        },
        {
            title: 'Affiliation',
            dataIndex: 'affiliation',
            // key: 'affiliation',
            render: (value, record, index) => (
                <span>{value}</span>
            ),
            sorter: (a, b) => a.affiliation.localeCompare(b.affiliation),
            filterDropdown: renderSearchBox("Search by affiliation"),
            onFilter: (value, record) => record.affiliation.toLowerCase().includes(value.toLowerCase())
        },
        {
            title: 'Abstract',
            dataIndex: 'abstractId',
            // key: 'abstractId',
            render: (value, record, index) => {
                if (value) return <AbstractSpan><Link to={`/editor/abstracts/${record.abstractId}`}><Icon
                    type="file"/></Link></AbstractSpan>;
                else return <></>
            },
            sorter: (a, b) => (a.abstractId === b.abstractId) ? 0 : a ? -1 : 1,
            width: 90
        },
        {
            title: 'Fee',
            dataIndex: 'paid',
            // key: 'paid',
            render: (value, record) => <Paid value={value} onClick={() => handleFeeStatusFormOpen(record)}/>,
            sorter: (a, b) => (a.paid === b.paid) ? 0 : a.paid ? -1 : 1,
            width: 100,

        },
        {
            title: 'Role',
            dataIndex: 'role',
            // key: 'role',
            render: (value, record) => (
                <UserRole role={value} onClick={() => handleRoleFormOpen(record)}/>
            ),
            sorter: (a, b) => a.role.localeCompare(b.role),
            width: 120,
        },
        {
            title: 'Registered',
            dataIndex: 'createdAt',
            // key: 'createdAt',
            render: (value, record, index) => (
                <span>{value.substring(0, 10)}</span>
            ),
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            defaultSortOrder: 'descend',
            width: 110,
        },
    ];

    // const rowRendered = (record, index) => {
    //     if(index === (users.length - 1)){
    //         setDataLoading(false);
    //     }
    // };

    const rowClassName = (record, index) => {
        if (record.id === lastId) {
            return styles.visitedRow
        }
    };


    const [page, setPage] = useQueryState('page', 1);
    const [pageSize, setPageSize] = useQueryState('pageSize', 15);

    const pagination = {
        defaultPageSize: pageSize,
        defaultCurrent: page,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['15', '50', '100', '200', '500'],
        onChange: (page) => setPage(page),
        onShowSizeChange: (current, size) => setPageSize(size),
    };


    return (
        <div>
            <NoPaddingCard title="Participants"
                           extra={<Button shape="round" disabled={disabledDownloadBtn}
                                          onClick={handleExportClick}>
                               <Icon type="download" spin={disabledDownloadBtn}/>
                           </Button>
                           }
            >
                <Scrollbar style={{height: 'calc(100vh - 235px)'}}>
                    <StyledTable
                        rowKey="id"
                        columns={columns}
                        dataSource={users}
                        size="small"
                        rowClassName={rowClassName}
                        // loading={dataLoading}
                        // onRow={rowRendered}
                        pagination={pagination}
                    />
                </Scrollbar>
            </NoPaddingCard>

            <Modal
                title={`Fee: ${_.get(feeState, 'user.firstName')} ${_.get(feeState, 'user.lastName')}`}
                visible={feeDialogVisible}
                onOk={handleFeeChange}
                onCancel={handleFeeChangeCancel}
                destroyOnClose={true}
            >
                <FeeStatusForm initialData={feeState} ref={feeStatusFormRef}/>
            </Modal>

            <Modal
                title={`Role: ${_.get(editedUser, 'firstName')} ${_.get(editedUser, 'lastName')}`}
                visible={roleDialogVisible}
                onOk={handleRoleChange}
                onCancel={handleRoleChangeCancel}
                destroyOnClose={true}
            >
                <RoleForm initialData={editedUser} ref={roleFormRef}/>
            </Modal>
        </div>
    );

};

AdminUsers.propTypes = {
    users: PropTypes.array.isRequired,
    exportUsers: PropTypes.func.isRequired,
    getUserFee: PropTypes.func.isRequired,
    setUserFee: PropTypes.func.isRequired,
    setUserRole: PropTypes.func.isRequired,
};

// AdminUsers.whyDidYouRender = {
//     // logOnDifferentValues: true,
//     customName: 'AdminUsers'
// };

export default withRouter(AdminUsers);

