import React from 'react'

export default {
    title: 'AutoMetForm 2020',
    subTitle: 'The 7th International Conference',
    conferenceSiteUrl: 'http://www.autometform.pwr.wroc.pl/',
    registrationFormText: (<span>Complete the form below in order to register for the Autometform Conference,
        May 18-19, 2020, Wroclaw, Poland. If you experience any problems with the site, you may wish to try another browser.
        If you continue to have problems, please contact us at <a href='mailto:autometform@pwr.edu.pl'>autometform@pwr.wroc.pl</a>.</span>),
    feeTabVisible: false,
    backgroundImage: '/background_amf2020.webp',
    abstractTemplate: '/Autometform_Abstract_Template.docx',
    paperTemplate: 'http://www.autometform.pwr.edu.pl/wp-content/uploads/2020/01/AutoMetForm-2020-Template.docx',
    copyrightsForm: 'http://www.autometform.pwr.edu.pl/wp-content/uploads/2020/01/COPYRIGHT-TRANSFER-FORM.doc',
    sentryDsn: 'https://f330b12906e744b89af857a106f0a4d4@sentry.kisim.eu.org/3',
    feeText: (<div style={{textAlign: 'center'}}>
        <p>
            Payment info
        </p>
    </div>),
    menu: {
        papersList: 'List of short papers',
        submitPaper: 'Submit short paper'
    },
    apiUrl: process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:9000/api',
}
