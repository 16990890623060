import {
    API_REVIEWER_ADD_PAPER_REVIEW,
    API_REVIEWER_ADD_REVIEW, API_REVIEWER_GET_ALL_PAPERS_REVIEWS,
    API_REVIEWER_GET_ALL_REVIEWS, API_REVIEWER_GET_PAPER,
    API_REVIEWER_GET_REVIEW_BY_ID
} from "../constants/action-types";

const initialState = {
    reviews: [],
    papersReviews: [],
    review: {}
};

const reviewerReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${API_REVIEWER_GET_ALL_REVIEWS}_SUCCESS`:
            return {
                ...state, reviews: action.payload.data.map((r, index) => { return {...r, no: index + 1}})
            }
        case `${API_REVIEWER_GET_ALL_PAPERS_REVIEWS}_SUCCESS`:
            return {
                ...state, papersReviews: action.payload.data.map((r, index) => { return {...r, no: index + 1}})
            };
        case `${API_REVIEWER_GET_PAPER}_SUCCESS`:
            return {
                ...state, paper: action.payload.data
            };
        case `${API_REVIEWER_ADD_PAPER_REVIEW}_SUCCESS`:
            return {
                ...state, paper: action.payload.data
            };
        case `${API_REVIEWER_GET_REVIEW_BY_ID}_SUCCESS`:
            return {
                ...state, review: action.payload.data
            }
        case `${API_REVIEWER_ADD_REVIEW}_SUCCESS`:
            return {
                ...state
            }
        default:
            return state

    }
}

export default reviewerReducer;
