import {
    API_REVIEWER_ADD_PAPER_REVIEW,
    API_REVIEWER_ADD_REVIEW, API_REVIEWER_GET_ALL_PAPERS_REVIEWS,
    API_REVIEWER_GET_ALL_REVIEWS, API_REVIEWER_GET_PAPER,
    API_REVIEWER_GET_REVIEW_BY_ID
} from "../constants/action-types";

export const reviewerGetReviews = () => {
    return {
        type: API_REVIEWER_GET_ALL_REVIEWS,
        payload: {
            request: {
                url: `/reviews`,
                method: 'get'
            }
        },
    }
}

export const reviewerGetPapersReviews = () => {
    return {
        type: API_REVIEWER_GET_ALL_PAPERS_REVIEWS,
        payload: {
            request: {
                url: `/reviews/papers`,
                method: 'get'
            }
        },
    }
}

export const reviewerGetPaperById = (id) => {
    return {
        type: API_REVIEWER_GET_PAPER,
        payload: {
            request: {
                url: `/reviews/papers/${id}`,
                method: 'get'
            }
        },
    }
}

export const reviewerGetReviewById = (id) => {
    return {
        type: API_REVIEWER_GET_REVIEW_BY_ID,
        payload: {
            request: {
                url: `/reviews/${id}`,
                method: 'get'
            }
        },
    }
}

export const reviewerAddReview = (review) => {
    return {
        type: API_REVIEWER_ADD_REVIEW,
        payload: {
            request: {
                url: `/reviews/${review.id}`,
                method: 'put',
                data: {
                    comment: review.comment,
                    decision: review.decision
                }
            }
        },
    }
}

export const reviewerAddPaperReview = (paperId, data) => {
    let formData = new FormData();
    if(data.files[0])
        formData.append('file', data.files[0].originFileObj);
    formData.append('status', data.decision);
    formData.append('comment', data.comment);

    return{
        type: API_REVIEWER_ADD_PAPER_REVIEW,
        payload: {
            request: {
                url: `reviews/papers/${paperId}`,
                method: 'put',
                data: formData
            }
        },
    }
}
