import React, {Component} from 'react';
import BasicLayout from "../../views/MainLayout";
import PaperView from "../../fragments/PaperView";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {
    clearPaper,
    deletePaper,
    getPaperById
} from "../../redux-actions/author";
import {If} from "babel-plugin-jsx-control-statements/macro"
import PaperReviewView from "../../fragments/PaperReviewView";
import AuthorReviewActions from "../../forms/AuthorReviewActions";
import PaperEditorCommentsView from "../../fragments/PaperEditorCommentsView";
import {conference} from "../../constants/strings";

class PaperPage extends Component {
    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.getPaperById(id)
    }

    render() {
        const {paper} = this.props;
        const cardStyle = {marginTop: 20}

        const shouldShowActions = conference.authorCanResponse.includes(paper.status);
        return (
            <BasicLayout>
                <PaperView
                    paper={paper}
                />
                <PaperEditorCommentsView
                    source={paper}
                    style={cardStyle}
                    data={paper?.editor}
                />

                {paper?.reviews?.map(rev => <PaperReviewView key={rev.id} style={cardStyle} review={rev}/>)}
                <If condition={shouldShowActions}>
                    <AuthorReviewActions style={cardStyle} action={() => this.props.history.push(`/papers/${paper.id}/resubmit`)}/>
                </If>
            </BasicLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: state.auth.user,
    paper: state.author.paper,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getPaperById: getPaperById,
        deletePaper: deletePaper,
        clearPaper: clearPaper,
    },
    dispatch,
)


export default connect(mapStateToProps, mapDispatchToProps)(PaperPage)

