import React, {Component} from 'react';
import BasicLayout from "../../views/MainLayout";
import Papers from "../../lists/Papers";
import {bindActionCreators} from "redux";
import {deletePaper, getPapers} from "../../redux-actions/author";
import connect from "react-redux/es/connect/connect";


class PapersListPage extends Component {

    render() {
        const {papers, deletePaper} = this.props
        return (
            <BasicLayout>
                <Papers papers={papers} deletePaper={deletePaper}/>
            </BasicLayout>
        );
    }

    componentDidMount() {
        this.props.getPapers()
    }
}

const mapStateToProps = (state, ownProps) => ({
    papers: state.author.papers
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getPapers: getPapers,
        deletePaper: deletePaper
    },
    dispatch,
)


PapersListPage = connect(mapStateToProps, mapDispatchToProps)(PapersListPage)

export default PapersListPage;
