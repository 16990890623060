import React, {useEffect} from 'react'
import UsersStatsPanel from "./UsersStatsPanel";
import AbstractsStatsPanel from "./AbstractsStatsPanel";
import EventsStatsPanel from "./EventsStatsPanel";
import {bindActionCreators} from "redux";
import {
    editorGetAbstractsStats,
    editorGetReviewersByReviews,
    editorGetUsersByCountry,
    editorGetUsersByEvent
} from "../../../redux-actions/editor";
import {connect} from "react-redux";
import {AbstractStatusChart} from "./AbstractStatusChart";
import ReviewersStatsPanel from "./ReviewersStatsPanel";

const StatsPanel = ({usersByCountry,
                        usersByEvent,
                        reviewersByReviews,
                        abstractsBySession,
                        sessions,
                        editorGetUsersByCountry,
                        editorGetUsersByEvent,
                        editorGetAbstractsStats,
                        editorGetReviewersByReviews

}) => {

    useEffect(() => {
        console.log("Effect")
        editorGetUsersByCountry();
        editorGetUsersByEvent();
        editorGetAbstractsStats();
        editorGetReviewersByReviews();
    }, [editorGetUsersByCountry, editorGetUsersByEvent, editorGetAbstractsStats ]);

    return (
        <>
        <UsersStatsPanel data={usersByCountry}/>
        <ReviewersStatsPanel data={reviewersByReviews}/>
        <AbstractsStatsPanel data={abstractsBySession} sessions={sessions}/>
        <EventsStatsPanel data={usersByEvent}/>
        <AbstractStatusChart data={[]}/>
        </>
    )
};

const mapStateToProps = (state, ownProps) => ({
    usersByCountry: state.editor.usersByCountry,
    usersByEvent: state.editor.usersByEvent,
    reviewersByReviews: state.editor.reviewersByReviews,
    abstractsBySession: state.editor.abstractsBySession,
    sessions: state.settings.sessions,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        editorGetUsersByCountry,
        editorGetUsersByEvent,
        editorGetAbstractsStats,
        editorGetReviewersByReviews,
    },
    dispatch,
);


export default connect(mapStateToProps, mapDispatchToProps)(StatsPanel)

