import React, {useEffect} from 'react';
import BasicLayout from "../../views/MainLayout";
import ReviewForm from "../../forms/Review";
import AbstractView from "../../fragments/AbstractView";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {reviewerAddReview, reviewerGetReviewById} from "../../redux-actions/reviewer";
import ReviewView from "../../fragments/ReviewView";
import _ from 'lodash-es'
import {If} from "babel-plugin-jsx-control-statements/macro"
import {useParams} from 'react-router-dom'
import {downloadAbstract} from "../../redux-actions/author";

const Review = ({review, getReviewById, addReview, sessions, downloadAbstract}) => {
    const {id} = useParams();

    useEffect(() => {
        getReviewById(id)
    }, [id]);

    const cardStyle = {marginTop: 20};
    const shouldShowReviewForm = _.get(review, 'abstract.status') === 'UNDER_REVIEW';

    return (
        <BasicLayout>
            <AbstractView
                abstract={review.abstract}
                sessions={sessions}
                sessionTitle={sessions.find(s => s.acronym === review?.abstract?.session)?.title ?? ''}
                downloadAbstract={downloadAbstract}
            />
            <ReviewView style={cardStyle} review={review}/>
            <If condition={shouldShowReviewForm}>
                <ReviewForm style={cardStyle} action={addReview} review={review}/>
            </If>
        </BasicLayout>
    );
};


const mapStateToProps = (state, ownProps) => ({
    review: state.reviewer.review,
    sessions: state.settings.sessions,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getReviewById: reviewerGetReviewById,
        addReview: reviewerAddReview,
        downloadAbstract: downloadAbstract
    },
    dispatch,
);


export default connect(mapStateToProps, mapDispatchToProps)(Review)

