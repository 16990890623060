import React, {Component} from 'react';
import {Button, Card, Icon, Tag, Timeline, Tooltip} from "antd";
import _ from "lodash-es";
import {conference} from "../constants/strings";
import {If} from "babel-plugin-jsx-control-statements/macro"
import config from "../config";
import download from 'downloadjs'

class PaperEditorCommentsView extends Component {
    render() {

        const {data, onEditClick, onPublishClick} = this.props;

        const comments = data ?? []
        let timeline = comments.map((item) => {
            const statusColor = conference.abstractStatusColor[item.status] || "rgba(0, 0, 0, 0.5)";
            const iconStyle = {fontSize: '16px', color: statusColor}

            return <Timeline.Item
                key={item.id}
                dot={<Icon type='info-circle' style={iconStyle} />}
                    >
                <Tag color={statusColor}>
                    {_.get(item, 'createdAt', '').substring(0, 10)}
                </Tag>
                <If condition={item.status}>
                    <Tooltip placement="top" title={conference.abstractStatusTip[item.status]}>
                        <Tag style={{width: 150, textAlign: "center"}} color={statusColor}>
                            {conference.abstractStatus[item.status]}
                        </Tag>
                    </Tooltip>
                </If>
                <If condition={item.published !== true && onEditClick && onPublishClick}>
                    <Button style={{marginRight: 10}} icon="edit" shape="round" size="small" type="primary"
                            onClick={() => onEditClick(item)}>Edit</Button>
                    <Button icon="arrow-right" shape="round" size="small" type="primary"
                            onClick={() => onPublishClick(item)}>Publish</Button>
                </If>
                <p style={{marginBottom: 0, whiteSpace: 'pre-wrap'}}>{_.get(item, 'comment')}</p>
                <If condition={item.file}>
                    <p style={{marginBottom: 0}}>
                        <Button
                            onClick={() => download(`${config.apiUrl}/papers/editor-comments/${item.id}/file`)}
                            style={{padding: 0}}
                            size="small"
                            type="link"
                            icon="cloud-download">
                            Attached file
                        </Button>
                    </p>
                </If>

            </Timeline.Item>
        })

        const title = `Editors comments`;
        return (
            <div>
                <Card title={title} style={{...this.props.style}} extra={this.props.actions}>
                    {/*<Spin spinning={_.isEmpty(review)}>*/}
                    <Timeline>
                        {timeline}
                    </Timeline>
                    {/*</Spin>*/}
                </Card>
            </div>
        );
    }
}

export default PaperEditorCommentsView;
