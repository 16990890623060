import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import BasicLayout from "../../views/MainLayout";
import PaperSubmissionForm from "../../forms/paper/PaperSubmission";
import {useHistory, useParams} from "react-router-dom";
import {message} from "antd";
import {editorGetPaperById, editorUpdatePaper} from "../../redux-actions/editor";
import {clearPaper, searchKeywords} from "../../redux-actions/author";

const AdminPaperEditPage = (props) => {
    let formRef = useRef(null);
    const dispatch = useDispatch();
    let history = useHistory();
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const {id} = useParams();

    const keywords = useSelector(state => state.author.keywords)
    const paper = useSelector(state => state.editor.paper)

    const handleUpdatePaper = useCallback((paperFile, copyrightFile, meta, paperId) => dispatch(editorUpdatePaper(paperFile, copyrightFile, meta, paperId)), [
        dispatch,
    ]);

    const handleClearPaper = useCallback(() => dispatch(clearPaper()), [
        dispatch,
    ]);

    const handleGetPaper = useCallback((id) => dispatch(editorGetPaperById(id)), [
        dispatch,
    ]);

    const handleKeywordsSearch = useCallback((keyword) => dispatch(searchKeywords(keyword)), [
        dispatch,
    ]);

    useEffect(() => {
        return () => {
            if (history.action === "POP")
                history.replace(history.location.pathname);
        }
    }, [history]);

    useEffect(() => {
        if (id) handleGetPaper(id)
    }, [id, handleGetPaper]);     // only initial render!


    useEffect(() => {
        return () => {
            handleClearPaper();
        }
    }, [handleClearPaper]);


    const handleSubmit = (e) => {
        e.preventDefault();

         formRef.current.getForm().validateFields((err, values) => {
            if (err) return;
            const {paperFile, copyrightFile, ...meta} = values;
            delete meta['keys'];

            setSubmitEnabled(false);

             handleUpdatePaper(paperFile[0].originFileObj, copyrightFile?.[0].originFileObj, meta, paper.id).then((res => {
                history.goBack();
            })).catch(exception => {
                message.error("The paper could not be updated due to errors.");
                setSubmitEnabled(true);
            });

        });
    };


    return (
        <BasicLayout>
            <PaperSubmissionForm
                type='edit'
                paper={paper}
                onSubmit={handleSubmit}
                submitEnabled={submitEnabled}
                keywords={Array.from(new Set(keywords))}
                keywordsSearch={handleKeywordsSearch}
                wrappedComponentRef={formRef}
            />
        </BasicLayout>
    );
};


export default AdminPaperEditPage;
