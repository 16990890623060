import React from 'react';
import {Button, Card} from 'antd';
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";
import ReviewResponse from "./ReviewResponse";


const AuthorReviewActions = ({action, style}) => {

    return (
        <div>
            <Card title="Actions" style={style}>
                <Button type="primary" icon="check" onClick={action} htmlType="button">Correct and resubmit</Button>
            </Card>
        </div>
    );

}

ReviewResponse.propTypes = {
    abstract: PropTypes.object,
}


export default withRouter(AuthorReviewActions)

