import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import BasicLayout from "../../views/MainLayout";
import React, {Component} from "react";
import {editorGetActivities, editorGetUsersByCountry} from "../../redux-actions/editor";
import UserMapPanel from "../../fragments/Editor/UsersMap/UserMapPanel";
import {Col, Row, Tabs} from "antd";
import StatsPanel from "../../fragments/Editor/StatsPanels";
import ActivityLog from "../../fragments/Editor/StatsPanels/ActivityLog";

const {TabPane} = Tabs;
class AdminDashboard extends Component{

    componentDidMount() {
        this.props.editorGetUsersByCountry()
    }

    render() {
        const {usersByCountry, editorGetActivities} = this.props;

        return (
            <BasicLayout>
                      <Tabs animated={false} >
                          <TabPane tab="Activity" key="activity">
                              <Row gutter={25}>
                                  <Col xs={12}><ActivityLog getActivities={editorGetActivities}/></Col>
                                  <Col xs={12}><StatsPanel/></Col>
                              </Row>
                          </TabPane>
                        <TabPane tab="Participants" key="participants">
                            <UserMapPanel data={usersByCountry}/>
                        </TabPane>
                    </Tabs>
            </BasicLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
        usersByCountry: state.editor.usersByCountry,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        editorGetUsersByCountry,
        editorGetActivities
    },
    dispatch,
)


AdminDashboard = connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)
export default AdminDashboard
