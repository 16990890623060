import React from 'react';
import {Icon, Menu} from "antd";
import {Link, withRouter} from "react-router-dom";
import {ROLE_ADMIN, ROLE_EDITOR, ROLE_REVIEWER} from "../constants/strings";
import {If} from "babel-plugin-jsx-control-statements/macro"
import config from '../config'

const SubMenu = Menu.SubMenu;
const {feeTabVisible} = config

const AppMenu = (props) => {
    const {user, location} = props;
    const {role, canSubmitPaper} = user;

    return (
        <Menu
            mode="inline"
            // defaultSelectedKeys={['/abstracts']}
            defaultOpenKeys={['author', 'reviewer', 'editor', 'admin']}
            style={{height: '100%'}}
            selectedKeys={[location.pathname]}
        >
            <SubMenu key="author" title={<span><Icon type='user'/>Author</span>}>
                <If condition={feeTabVisible}>
                    <Menu.Item key="/fee">
                        <Link to='/fee'>Fee</Link>
                    </Menu.Item>
                </If>
                <Menu.Item key="/documents">
                    <Link to='/documents'>Documents</Link>
                </Menu.Item>
                <Menu.Item key="/abstracts">
                    <Link to='/abstracts'>List of abstracts</Link>
                </Menu.Item>
                <Menu.Item key="/abstracts/add">
                    <Link to='/abstracts/add'>Submit new abstract</Link>
                </Menu.Item>
                <Menu.Item key="/papers">
                    <Link to='/papers'>{config.menu.papersList}</Link>
                </Menu.Item>
                <Menu.Item key="/papers/add" disabled={!canSubmitPaper}>
                    <Link to='/papers/add'>{config.menu.submitPaper}</Link>
                </Menu.Item>
            </SubMenu>
            <If condition={role === ROLE_REVIEWER || role === ROLE_EDITOR || role === ROLE_ADMIN}>
                <SubMenu key="reviewer" title={<span><Icon type="read"/>Reviewer</span>}>
                    <Menu.Item key="/reviews">
                        <Link to='/reviews'>Abstracts Reviews</Link>
                    </Menu.Item>
                    <Menu.Item key="/papers-reviews">
                        <Link to='/papers-reviews'>Papers Reviews</Link>
                    </Menu.Item>
                </SubMenu>
            </If>
            <If condition={role === ROLE_EDITOR}>
                <SubMenu key="editor" title={<span><Icon type="form"/>Editor</span>}>
                    <Menu.Item key="/editor/papers">
                        <Link to='/editor/papers'>Papers</Link>
                    </Menu.Item>
                </SubMenu>
            </If>
            <If condition={role === ROLE_ADMIN}>
                <SubMenu key="admin" title={<span><Icon type="poweroff"/>Administrator</span>}>
                    <Menu.Item key="/editor/dashboard">
                        <Link to='/editor/dashboard'>Dashboard</Link>
                    </Menu.Item>
                    <Menu.Item key="/editor/users">
                        <Link to='/editor/users'>Participants</Link>
                    </Menu.Item>
                    <Menu.Item key="/editor/abstracts">
                        <Link to='/editor/abstracts'>Abstracts</Link>
                    </Menu.Item>
                    <Menu.Item key="/editor/papers">
                        <Link to='/editor/papers'>Papers</Link>
                    </Menu.Item>
                    <Menu.Item key="/editor/settings">
                        <Link to='/editor/settings'>Settings</Link>
                    </Menu.Item>
                </SubMenu>
            </If>
        </Menu>
    );
}


export default withRouter(AppMenu);
