import React, {PureComponent} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Registration from "./pages/Registration";
import AbstractsList from "./pages/author/AbstractsList";
import AbstractPage from "./pages/author/AbstractPage"
import PaperPage from "./pages/author/Paper"
import AbstractSubmission from "./pages/author/AbstractSubmission";
import ReviewsList from "./pages/reviewer/ReviewsList";
import Review from "./pages/reviewer/Review";
import NotFound from "./pages/NotFound";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {ROLE_ADMIN, ROLE_AUTHOR, ROLE_EDITOR, ROLE_REVIEWER} from "./constants/strings";
import AdminAbstractsList from "./pages/admin/AdminAbstractsList";
import AdminAbstractsView from './pages/admin/AdminAbstract'
import AdminUsers from "./pages/admin/AdminUsersList";
import AdminUserView from "./pages/admin/AdminUser";
import ProfilePage from "./pages/author/ProfilePage";
import FeePage from "./pages/author/FeePage";
import DocumentsPage from "./pages/author/Documents";
// import AbstractDownload from "./pages/author/AbstractDownload";
import jwtDecode from 'jwt-decode'
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminSettings from "./pages/admin/AdminSettings";
import PapersListPage from "./pages/author/PapersList";
import PaperReviewsList from "./pages/reviewer/PaperReviewsList";
import PaperReview from "./pages/reviewer/PaperReview";
import PaperSubmissionPage from "./pages/author/PaperSubmission";
import AdminPapersListPage from "./pages/admin/AdminPapersList";
import AdminPaperPage from "./pages/admin/AdminPaper";
import {AdminImpersonateUser} from "./pages/admin/AdminImpersonateUser";
import AdminPaperEditPage from "./pages/admin/AdminPaperEdit";

const AuthRoute = ({auth, roles, component, path, location, ...rest}) => {
    // If role is not required
    if(!roles)
        return <Route path={path} component={component}/>

    // If user is authenticated
    if (auth.token){
        // if main site -> redirect to profile
        if (rest.path === "/")
            return <Redirect to='/profile'/>


        // checks permission
        const tokenData = jwtDecode(auth.token);
        const canAccess = roles.includes(tokenData.role)
        if(canAccess)
            return <Route path={path} component={component}/>
    }

    // No authenticated
    return <Redirect to={`/?redirect=${location.pathname}`}/>

}


class Routing extends PureComponent {
    render() {
        const {auth, location} = this.props;

        const authorAccess = [ROLE_AUTHOR, ROLE_REVIEWER, ROLE_EDITOR, ROLE_ADMIN];
        const reviewsAccess = [ROLE_REVIEWER, ROLE_EDITOR, ROLE_ADMIN];
        const editorAccess = [ROLE_EDITOR, ROLE_ADMIN];
        const adminAccess = [ROLE_ADMIN];
        return (
            <Switch>
                <AuthRoute auth={auth} path="/" exact component={Login} />
                <AuthRoute auth={auth} path="/forgot-password" component={ForgotPassword} />
                <AuthRoute auth={auth} path="/reset-password/:token" component={ResetPassword} />
                <AuthRoute auth={auth} path="/register" component={Registration} />
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/fee" exact component={FeePage}/>
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/documents" exact component={DocumentsPage}/>
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/profile" exact component={ProfilePage}/>
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/abstracts" exact component={AbstractsList} />

                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/abstracts/add" exact component={() => <AbstractSubmission type="submit"/>} />
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/abstracts/:id" exact component={AbstractPage} />
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/abstracts/:id/edit" component={() => <AbstractSubmission type="edit"/>}/>
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/abstracts/:id/resubmit" component={() => <AbstractSubmission type="resubmit"/>}/>

                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/papers" exact component={PapersListPage} />
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/papers/add" exact component={() => <PaperSubmissionPage type="submit"/>} />
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/papers/:id" exact component={PaperPage} />
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/papers/:id/edit" component={() => <PaperSubmissionPage type="edit"/>}/>
                <AuthRoute auth={auth} roles={authorAccess} location={location} path="/papers/:id/resubmit" component={() => <PaperSubmissionPage type="resubmit"/>}/>

                <AuthRoute auth={auth} roles={reviewsAccess} location={location} path="/reviews" exact component={ReviewsList}/>
                <AuthRoute auth={auth} roles={reviewsAccess} location={location} path="/reviews/:id" exact component={Review} />

                <AuthRoute auth={auth} roles={reviewsAccess} location={location} path="/papers-reviews" exact component={PaperReviewsList}/>
                <AuthRoute auth={auth} roles={reviewsAccess} location={location} path="/papers-reviews/:id" exact component={PaperReview} />

                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/abstracts" exact component={AdminAbstractsList}/>
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/abstracts/:id" component={AdminAbstractsView}/>
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/users" exact component={AdminUsers}/>
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/dashboard" exact component={AdminDashboard}/>
                <AuthRoute auth={auth} location={location} path="/editor/users/:id/impersonate"  component={AdminImpersonateUser}/>
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/users/:id/:tab?"  component={AdminUserView}/>
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/settings"  component={AdminSettings}/>
                <AuthRoute auth={auth} roles={editorAccess} location={location} path="/editor/papers/:id/edit"  component={AdminPaperEditPage}/>
                <AuthRoute auth={auth} roles={editorAccess} location={location} path="/editor/papers/:id"  component={AdminPaperPage}/>
                <AuthRoute auth={auth} roles={editorAccess} location={location} path="/editor/papers"  component={AdminPapersListPage}/>
                <Route component={NotFound}/>
            </Switch>
        );
    }
}

Routing.propTypes = {};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch,
)


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routing));
