import React, {Component} from 'react';
import {Button, Tag, Tooltip} from 'antd';
import {Link, withRouter} from "react-router-dom";
import styles from "./Abstracts.module.scss";
import PropTypes from "prop-types"
import {conference} from "../constants/strings";
import {StyledTable} from "./Components/StyledTable";
import {NoPaddingCard} from "./Components/NoPaddingCard";

class Papers extends Component {

    columns = [{
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (title, record) => (
            <span><Link to={`/papers/${record.id}`}>{title}</Link></span>
        )
    },
        {
            title: 'Authors',
            dataIndex: 'authors',
            render: (authors, record) => (<ul className={styles.authorList}>{authors.map((author, authorIndex) => <li
                key={author.id}
                style={{textDecoration: authorIndex === record.presenter ? 'underline' : 'none'}}>{author.lastName} {author.firstName}</li>)}</ul>),
            key: 'author',
            width: 250,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <span>
                    <Tag color={conference.abstractStatusColor[status]} >{conference.abstractStatus[status]}</Tag>
                </span>
            ),
            width: 150,
        }, {
            title: 'Submission date',
            key: 'submissionDate',
            dataIndex: 'createdAt',
            render: date => <span>{date.substring(0, 10)}</span>,
            width: 150,
        }, {
            title: 'Action',
            key: 'action',
            width: 120,
            render: (text, record) => (
                <div>
                    <Tooltip placement="bottom" title="Delete submitted paper">
                        <Button
                            style={{margin: 5}}
                            type='danger'
                            shape="circle"
                            disabled={!conference.authorCanDelete.some(status => status === record.status)}
                            icon="delete"
                            onClick={() => this.props.deletePaper(record.id)}
                        />
                    </Tooltip>

                    <Tooltip placement="bottom" title="Reply to the reviewer">
                        <Button
                            style={{margin: 5}}
                            type='primary'
                            shape="circle"
                            disabled={!conference.authorCanResponse.some(status => status === record.status)}
                            icon="sync"
                            onClick={() => this.props.history.push(`/papers/${record.id}/resubmit`)}
                        />
                    </Tooltip>

                </div>
            ),
        }];

    render() {
        return (
            <div>
                <NoPaddingCard title="Submitted papers">
                    <StyledTable
                        rowKey="id"
                        columns={this.columns}
                        dataSource={this.props.papers}
                        pagination={false}
                    />
                </NoPaddingCard>
            </div>
        );
    }
}

Papers.propTypes = {
    papers: PropTypes.array,
    deletePaper: PropTypes.func.isRequired
};
Papers = withRouter(Papers)

export default Papers;
