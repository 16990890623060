import React from "react"
import {Col, Collapse, List, Row} from "antd/lib/index";
import {Badge} from "antd";

const Panel = Collapse.Panel;

function ReviewersStatsPanel(props) {

    return (
        <Collapse>
        <Panel header="Reviewers" key={'reviewers-stats'}>
            <Row>
                <Col xs={24} >
                    <div>
                        <List
                            size="small"
                            dataSource={props.data}
                            renderItem={item => (
                                <List.Item key={item._id}>
                                    <div style={{width: '90%', display: 'inline-block'}}>
                                        {item.firstName} {item.lastName}
                                    </div>
                                    <div style={{display: 'inline-block'}}>
                                        <Badge showZero style={{ backgroundColor: '#1eaf39' }}  count={item.count}/>
                                    </div>
                                </List.Item>
                            )}
                        >
                        </List>
                    </div>
                </Col>
            </Row>
        </Panel>
        </Collapse>
    )
}

export default ReviewersStatsPanel;
