import React from 'react';
import {Alert, Form, Select} from "antd";
import PropTypes from "prop-types";

const FormItem = Form.Item;

const AssignReviewersForm = (props) => {
    const {getFieldDecorator} = props.form;
    const initial = props.source.reviews.map(review => review.reviewer.id);

    const handleFilter = (input, option) => {
        const valueNormalized = input.toLowerCase();
        const optionText = option.props.children.toLowerCase();
        return optionText.includes(valueNormalized);
    };

    return (
        <Form onSubmit={props.handleSubmit}>
            <Alert style={{marginBottom: 10}} message="Assign reviewers. Selected reviewers will be notified by email." type="info" />
            <FormItem
            >
                {getFieldDecorator('reviewer', {
                    rules: [{
                        required: true, message: 'Select reviewer',
                    }],
                    initialValue: initial
                })(
                    <Select
                        mode="multiple"
                        placeholder="Select reviewers"
                        style={{width: '100%'}}
                        filterOption={handleFilter}
                    >
                        {props.reviewers.map((reviewer) => <Select.Option value={reviewer.id}
                                                                       key={reviewer.id}>{`${reviewer.firstName} ${reviewer.lastName}`}</Select.Option>)}
                    </Select>
                )}
            </FormItem>
        </Form>
    );
};

AssignReviewersForm.propTypes = {
    source: PropTypes.object.isRequired,
};
AssignReviewersForm.defaultProps = {};

export default Form.create()(AssignReviewersForm)

