import React, {useRef} from 'react';
import {Button, Input, Popconfirm, Tag} from 'antd';
import {Link} from "react-router-dom";
import {conference} from "../constants/strings";
// import download from "downloadjs";
// import cd from 'content-disposition'
import {Scrollbar} from "react-scrollbars-custom";
import {NoPaddingCard} from "./Components/NoPaddingCard";
import {StyledTable} from "./Components/StyledTable";
import styles from "./AdminPapers.module.scss"
import {useQueryState} from "react-router-use-location-state";
import {OverdueTag} from "./Components/OverdueTag";
import {EditorActionTag} from "./Components/EditorActionTag";

const PaperStatus = ({label, status}) => {
    return (
        <span style={{display: 'block'}}>
            <span style={{width: 30, display: 'inline-block'}}>{label}</span>
            <Tag style={{width: 140, textAlign: 'center'}}
                 color={conference.abstractStatusColor[status]}>{conference.abstractStatus[status]}</Tag>
        </span>
    )
}

const ActionsButtons = ({paper, deleteAction}) => {
    return (
        <Popconfirm
            title="Are you sure delete this paper?"
            onConfirm={() => deleteAction(paper)}
            okText="Yes"
            cancelText="No"
        >
            <Button icon="delete" type="danger" shape="circle"/>
        </Popconfirm>

    )
}

const AdminPapers = ({ isAdmin, papers, downloadAllPapers, deletePaper}) => {
    // const [dataLoading, setDataLoading] = useState(true);
    // const [disabledDwnBtn, setDisabledDwnBtn] = useState(false);
    const searchInputRef = useRef(null);

    const handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    const handleReset = clearFilters => () => {
        clearFilters();
    };

    const renderSearchBox = (dialogTitle) => {
        return ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div className={styles.searchBox}>
                <Input
                    ref={searchInputRef}
                    placeholder={dialogTitle}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={handleSearch(selectedKeys, confirm)}
                />
                <Button type="primary" onClick={handleSearch(selectedKeys, confirm)}>Search</Button>
                <Button onClick={handleReset(clearFilters)}>Reset</Button>
            </div>
        )
    };

    const renderAuthors = (authors, isAdmin) => {

        if(isAdmin)
            return (
                <ul className={styles.authorList}>
                    {authors.map(author => {
                        if(author.refId)
                            return (<li key={author.id}><Link to={`/editor/users/${author.refId}`}>{author.firstName} {author.lastName}</Link></li>);
                        return (<li key={author.id}>{author.firstName} {author.lastName}</li>)
                        }
                    )}
                </ul>
            );
        else
            return (
                <ul className={styles.authorList}>
                    {authors.map(author => {
                            return (<li key={author.id}>{author.firstName} {author.lastName}</li>)
                        }
                    )}
                </ul>
            )
    };

    const [page, setPage] = useQueryState('page', 1);
    const [pageSize, setPageSize] = useQueryState('pageSize', 500);

    const pagination = {
        defaultPageSize: pageSize,
        defaultCurrent: page,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['15', '50', '100', '200', '500'],
        onChange: (page) => setPage(page),
        onShowSizeChange: (current, size) => setPageSize(size),
    };

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            width: 60
        },
        {
            title: 'Title',
            dataIndex: 'title',

            filterDropdown: renderSearchBox("Search by title"),
            onFilter: (value, record) => record.title.toLowerCase().includes(value.toLowerCase()),
            render: (title, record) => <Link to={`/editor/papers/${record.id}`} >{title}</Link>,
        },
        {
            title: 'Authors',
            dataIndex: 'authors',
            render: (authors, record) => renderAuthors(authors, isAdmin),

            width: '15%',
            filterDropdown: renderSearchBox("Search by author"),
            onFilter: (value, record) => record.authors.filter(author => author.lastName.toLowerCase() === value.toLowerCase() ||
                author.firstName.toLowerCase() === value.toLowerCase()).length > 0
        },
        {
            title: 'Overdue review',
            dataIndex: 'overdue',
            width: 155,
            render: (overdue, record) => <OverdueTag overdue={overdue} date={record.longestReview}/>,
            sorter: (a, b) => a !== b,
        },
        {
            title: 'Editor action',
            dataIndex: 'actionRequired',
            render: (action, record) => <EditorActionTag action={action}/>,
            width: 150,
            sorter: (a, b) => a !== b,
        },
        {
            title: 'Status',
            width: 220,
            dataIndex: 'status',
            render: (status, paper) => (
                <span>
                    {
                        paper.reviewStatuses.map((s, index) => (
                            <PaperStatus status={s} key={`${paper.id}-r${index}`} label={`R${index + 1}: `}/>))
                    }
                    <PaperStatus status={status} label={`E: `}/>
                </span>
            ),
            sorter: (a, b) => a.status.localeCompare(b.status),       // TODO: Fix it :)
        }, {
            title: 'Created at',
            dataIndex: 'createdAt',
            width: 120,
            defaultSortOrder: 'descend',
            render: date => date.substring(0, 10),
            sorter: (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt),
        }, {
            title: 'Last update',
            dataIndex: 'updatedAt',
            width: 120,
            render: date => date.substring(0, 10),
            sorter: (a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt),
        },
        isAdmin ? {
            title: 'Actions',
            key: 'actions',
            width: 80,
            render: paper => <ActionsButtons paper={paper} deleteAction={deletePaper}/>,
        } : {},

        ];


    // const handleDownloadAll = () => {
    //     setDisabledDwnBtn(true);
    //     downloadAllPapers()
    //         .then(response => {
    //             const disposition = cd.parse(response.payload.headers['content-disposition']);
    //             setDisabledDwnBtn(false);
    //             return download(response.payload.data, disposition.parameters.filename);
    //         })
    // };

    // const rowRendered = (record, index) => {
    //     if(index === (abstracts.length - 1)){
    //         setDataLoading(false);
    //     }
    // };

    // const rowClassName = (record, index) => {
    //     return record.overdue ? styles.actionRequired : null;
    // }

    return (
            <div>
                <NoPaddingCard
                //     extra={
                //     handleDownloadAll &&
                //         <Button shape="round" disabled={disabledDwnBtn} onClick={handleDownloadAll}>
                //             <Icon type="download" spin={disabledDwnBtn}/>
                //         </Button>
                // }
                               title='Submitted papers'>
                    <Scrollbar style={{height: 'calc(100vh - 235px)'}}>
                        <StyledTable rowKey="id"
                                     // onRow={rowRendered}
                                     // rowClassName={rowClassName}
                                     // loading={dataLoading}
                                     columns={columns}
                                     dataSource={papers}
                                     pagination={pagination}
                        />
                    </Scrollbar>
                </NoPaddingCard>
            </div>
        );

};

AdminPapers.whyDidYouRender = {
    // logOnDifferentValues: true,
    customName: 'AdminPapers'
}


export default AdminPapers


