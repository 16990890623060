import React, {Component} from 'react';
import {Button, Card, Col, Row, Spin} from "antd";
import _ from 'lodash-es'
import styles from './Abstract.module.scss'
import {withRouter} from "react-router-dom";
import config from "../config";
import {If} from "babel-plugin-jsx-control-statements/macro"
import {conference} from "../constants/strings";
import download from 'downloadjs'

class AbstractView extends Component {

    renderAuthors = (authors) => {
        return (
            <ul className={styles.authorList}>
                {authors.map((author, idx) => {
                        return (<li key={author.id}>
                                {
                                    <span>
                                        {author.firstName} {author.lastName}, {author.affiliation}
                                        <a href={`mailto:${author.email}`}><Button size="small" type="link" icon="mail"/></a>

                                    </span>
                                }
                        </li>)
                    }
                )}</ul>
        )
    };

    render() {
        const {paper, extraButtons, isAdmin} = this.props;

        let authors = [];
        let keywords = '';
        if (!_.isEmpty(paper)) {
            authors = this.renderAuthors(paper.authors);
            keywords = _.get(paper, 'keywords', []).join(', ');
        }

        const columnSizes = {
            header: {
                xs: 24,
                md: 6,
                xl: 2
            },
            data: {
                xs: 24,
                md: 18,
                xl: 22
            }

        };

        return (
          <div>
              <Card
                  title="Paper"
                  extra={isAdmin ? extraButtons : null}
              >
                  <Spin spinning={_.isEmpty(paper)}>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Title:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{_.get(paper, 'title', '')}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Submitter:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>
                                  <span>
                                        {paper?.owner?.firstName} {paper?.owner?.lastName}, {paper?.owner?.affiliation}
                                      <a href={`mailto:${paper?.owner?.email}`}>
                                          <Button size="small" type="link" icon="mail"/></a>
                                    </span>
                              </div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Authors:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{authors}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Keywords:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{keywords}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Paper:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                                  <Button
                                      onClick={() => download(`${config.apiUrl}/papers/${paper?.id}/paper`)}
                                      style={{padding: 0}}
                                      size="small"
                                      type="link"
                                      icon="cloud-download">
                                      Download paper
                                  </Button>
                          </Col>
                      </Row>
                      <If condition={config.copyrightsForm}>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Copyrights form:</div>
                          </Col>

                              <Col {...columnSizes.data}>
                                   <Button
                                       onClick={() => download(`${config.apiUrl}/papers/${paper?.id}/copyright`)}
                                       style={{padding: 0}}
                                       size="small"
                                       type="link"
                                       icon="cloud-download">
                                       Download copyrights form
                                   </Button>
                              </Col>
                      </Row>
                      </If>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Status:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              {conference.abstractStatus[paper?.status]}
                          </Col>
                      </Row>
                  </Spin>
              </Card>

          </div>
        );
    }
}


export default withRouter(AbstractView)
