import React from 'react'
import {Button, Col, Divider, Popconfirm, Row} from "antd";
import {useHistory} from "react-router-dom";
import styled from "styled-components"

const Wrapper = styled.div`
    margin: 10px;
`;

const Actions = ({userRemove, user}) => {
    let history = useHistory();

    const removeParticipant = () => {
        userRemove(user).then((response) => {
            history.push({
                pathname: `/editor/users/`
            });
        })
    };

    const handleImpersonate = () => {
        const userId = user.id;
        window.open(window.location.origin + `/editor/users/${userId}/impersonate`, '_blank');
    }

    return (
        <Row type='flex' justify='center'>
            <Divider>Actions</Divider>
            <Col span={8}>
                <Wrapper>
                    <Button block type="primary" icon='team' onClick={handleImpersonate}>Impersonate</Button>
                </Wrapper>

                <Wrapper>
                    <Popconfirm placement="bottom" title='Are you sure to delete this participant?' onConfirm={removeParticipant} okText="Yes" cancelText="No">
                        <Button block type="danger" icon='delete'>Delete account</Button>
                    </Popconfirm>
                </Wrapper>
            </Col>
        </Row>
    )
};

export default Actions
