import React from "react";
import {Button, Form, Icon, Input, Select, Upload} from 'antd';
import styled from 'styled-components'
import config from "../config";

const { Option } = Select;
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;
`

const uploaderProps = {
    multiple: false,
    accept: '.doc, .docx, .pdf, .zip, .gz',
    beforeUpload: file => {
        return false
    }
};

const normFile = e => {
    if (Array.isArray(e)) {
        return e;
    }
    e.fileList = e.fileList.slice(-1);

    return e && e.fileList;
};

const PaperEditorForm = ({form, data, parentPaper}) => {
    const {getFieldDecorator} = form;
    return (
            <Form layout="horizontal">
                        <StyledFormItem label="Paper status" {...layout}>
                            {getFieldDecorator('status', {
                                initialValue: data.status,
                                rules: [{
                                    required: true,
                                }]
                            })(
                                <Select >
                                    <Option value="ACCEPTED">Accepted</Option>
                                    <Option value="CONDITIONALLY_ACCEPTED">Conditionally accepted</Option>
                                    <Option value="REVISE_REQUIRED">Needs to be revised</Option>
                                    <Option value="REJECTED">Rejected</Option>
                                </Select>,
                            )}
                        </StyledFormItem>
                <StyledFormItem label="Comments"  {...layout}>
                        {getFieldDecorator('comment', {
                            initialValue: data.comment,
                        })(
                            <Input.TextArea rows={5}/>
                        )}
                </StyledFormItem>
                <StyledFormItem label="File"  {...layout}>
                    {getFieldDecorator('file', {
                        valuePropName: 'fileList',
                        getValueFromEvent: normFile,
                        initialValue: data.file ? [{
                            uid: data.file,
                            name: `Attached file`,
                            url: `${config.apiUrl}/papers/${parentPaper.id}/editor/${data.id}`,
                            status: 'done'
                        }] : []
                    })(
                        <Upload {...uploaderProps}>
                            <Button>
                                <Icon type="cloud-upload" /> Click to Upload
                            </Button>
                          </Upload>
                    )}
                </StyledFormItem>
            </Form>

    )
};

export default Form.create()(PaperEditorForm)
