import React, {useEffect} from 'react';
import {Tag} from 'antd';
import {Link, withRouter} from "react-router-dom";
import styles from "./Reviews.module.scss";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {reviewerGetPapersReviews} from "../redux-actions/reviewer";
import {conference} from "../constants/strings";
import {NoPaddingCard} from "./Components/NoPaddingCard";
import {StyledTable} from "./Components/StyledTable";
import {Scrollbar} from "react-scrollbars-custom";
import {useQueryState} from "react-router-use-location-state";

const PaperReviews = (props) => {

    const {getReviews} = props;
    useEffect(() => {
            getReviews()
        }, [getReviews]);


    const renderAuthors = (paper) => {
        if(!paper.authors) return;

        const paperId = paper.id;
        return <ul className={styles.authorList}>{paper.authors.map(author => <li
            key={`${paperId}_${author.id}`}>{author.lastName} {author.firstName}</li>)}</ul>
    }

    const [page, setPage] = useQueryState('page', 1);
    const [pageSize, setPageSize] = useQueryState('pageSize', props.reviews.length);


    const pagination = {
        defaultPageSize: pageSize,
        defaultCurrent: page,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['15', '50', '100', '200'],
        onChange: (page) => setPage(page),
        onShowSizeChange: (current, size) => setPageSize(size),
    };

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            width: 60,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
            render: (title, record) => <span><Link to={`/papers-reviews/${record.id}`}>{title}</Link></span>
        },
        {
            title: 'Authors',
            render: (paper, index) => renderAuthors(paper),
            key: 'author',
            width: 200,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <span>
                    <Tag color={conference.abstractStatusColor[status]}
                         key={status}>{conference.abstractStatus[status]}</Tag>
                </span>
            ),
            width: 150,
            sorter: (a, b) => a.abstract.status.localeCompare(b.abstract.status),
        }, {
            title: 'Assign date',
            key: 'submissionDate',
            dataIndex: 'createdAt',
            width: 150,
            defaultSortOrder: 'descend',
            render: date => date.substring(0, 10),
            sorter: (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt),
        }, {
            title: 'Last update',
            key: 'updateDate',
            dataIndex: 'updatedAt',
            width: 160,
            defaultSortOrder: 'descend',
            render: date => date.substring(0, 10),
            sorter: (a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt),
        }];


    return (
        <div>
            <NoPaddingCard title="Reviews">
                <Scrollbar style={{height: 'calc(100vh - 235px)'}}>
                    <StyledTable
                        rowKey="id"
                        columns={columns}
                        dataSource={props.reviews}
                        pagination={pagination}
                    />
                </Scrollbar>
            </NoPaddingCard>
        </div>
    );

}


const mapStateToProps = (state, ownProps) => ({
    reviews: state.reviewer.papersReviews
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getReviews: reviewerGetPapersReviews,
    },
    dispatch,
)


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaperReviews))

