import React, {useEffect} from 'react';
import BasicLayout from "../../views/MainLayout";
import {bindActionCreators} from "redux";
import {If} from "babel-plugin-jsx-control-statements/macro"
import connect from "react-redux/es/connect/connect";
import {
    reviewerAddPaperReview,
    reviewerGetPaperById,
} from "../../redux-actions/reviewer";
import _ from 'lodash-es'
import {useParams} from 'react-router-dom'
import {downloadAbstract} from "../../redux-actions/author";
import PaperView from "../../fragments/PaperView";
import PaperReviewView from "../../fragments/PaperReviewView";
import PaperReviewForm from "../../forms/PaperReview";

const PaperReview = ({paper, getPaperById, addPaperReview}) => {
    const {id} = useParams();

    useEffect(() => {
        getPaperById(id)
    }, [id, getPaperById]);

    const cardStyle = {marginTop: 20};
    const shouldShowReviewForm = _.get(paper, 'status') === 'UNDER_REVIEW';

    return (
        <BasicLayout>
            <PaperView
                paper={paper}
            />
            <PaperReviewView style={cardStyle} review={paper?.reviews[0]}/>
            <If condition={shouldShowReviewForm}>
                <PaperReviewForm style={cardStyle} action={addPaperReview} paper={paper}/>
            </If>
        </BasicLayout>
    );
};


const mapStateToProps = (state, ownProps) => ({
    paper: state.reviewer.paper,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getPaperById: reviewerGetPaperById,
        addPaperReview: reviewerAddPaperReview,
        downloadAbstract: downloadAbstract
    },
    dispatch,
);


export default connect(mapStateToProps, mapDispatchToProps)(PaperReview)

