import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import 'antd/dist/antd.css';
import {BrowserRouter} from 'react-router-dom'
import Routing from "./Routing";
import configureStore from "./redux-store";
import {Provider} from "react-redux";
import {getCurrentUser, retrieveAuthFromStorage} from "./redux-actions/auth";
import {getPublicSettings} from "./redux-actions/settings";
import {Helmet} from "react-helmet";
import CacheBuster from './CacheBuster'

import config from "./config";

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    Sentry.init({dsn: config.sentryDsn, release: 'suberus-gui@' + global.appVersion});
} else {
    console.log('Sentry init skipped');
}

const store = configureStore()

class App extends Component {
    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                        return <></>
                    }
                    else {
                        store.dispatch(retrieveAuthFromStorage())
                        store.dispatch(getPublicSettings())

                        if (store.getState().auth.token)
                            store.dispatch(getCurrentUser())


                        return (
                            <Provider store={store}>
                                <Helmet>
                                    <title>{config.title}</title>
                                </Helmet>

                                <BrowserRouter>
                                    <Routing/>
                                </BrowserRouter>
                            </Provider>
                        );
                    }
                }}
            </CacheBuster>
        );
    }
}

export default App;
