import React from 'react';
import {Button, Card, Col, Form, Icon, Input, Radio, Row, Tooltip, Upload} from 'antd';
import {withRouter} from "react-router-dom";
import {conference} from "../constants/strings";

const FormItem = Form.Item;

const uploaderProps = {
    multiple: false,
    accept: '.doc, .docx, .pdf, .zip, .gz, .rar',
    beforeUpload: file => false
};

const normFile = e => {
    if (Array.isArray(e)) {
        return e;
    }
    e.fileList = e.fileList.slice(-1);

    return e && e.fileList;
};

class PaperReviewForm extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.action(
                    this.props.paper.id, values
                ).then((response) => {
                    this.props.history.push('/papers-reviews')
                })
            }
        });
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                sm: {span: 24},
            },
            wrapperCol: {
                sm: {span: 24},
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                sm: {span: 22},
            },
        };
        getFieldDecorator('keys', {initialValue: [{}]});

        const decisionOptions = conference.reviewDecision.map(decision => <Radio
            value={decision} key={decision}>{conference.abstractStatus[decision]}</Radio>)
        return (
            <div>
                <Card title="Review form" style={{...this.props.style}}>
                    <Form onSubmit={this.handleSubmit} layout='vertical'>
                        <Row>
                            <Col xs={3}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Decision"
                                >
                                    {getFieldDecorator('decision', {
                                        rules: [
                                            {required: true, message: 'Please select your decision!'},
                                        ],
                                    })(
                                        <Radio.Group>
                                            {decisionOptions}
                                        </Radio.Group>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={12}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Comments"
                                >
                                    {getFieldDecorator('comment', {})(
                                        <Input.TextArea rows={4}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <FormItem {...formItemLayout} label="Additional notes (optional)">
                            {getFieldDecorator('files', {
                                valuePropName: 'fileList',
                                getValueFromEvent: normFile,
                                initialValue: []
                            })(
                                <Upload {...uploaderProps}>
                                    <Tooltip placement="rightTop" title="Allowed: .doc, .docx, .pdf, .zip, .gz, .rar">
                                        <Button block>
                                            <Icon type="cloud-upload"/> Click to upload file
                                        </Button>
                                    </Tooltip>
                                </Upload>
                            )}
                        </FormItem>

                        <FormItem {...formItemLayoutWithOutLabel}>
                            <Button style={{width: 169}} type="primary" htmlType="submit">Submit</Button>
                        </FormItem>

                    </Form>
                </Card>
            </div>
        );
    }
}

PaperReviewForm = Form.create()(PaperReviewForm);
PaperReviewForm = withRouter(PaperReviewForm);
export default PaperReviewForm

