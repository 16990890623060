import {Tag} from "antd";
import React from "react";
import styled from "styled-components"

const StyledTag = styled(Tag)`
  width: 100px;
  text-align: center;
`

export const EditorActionTag = ({action}) => {
    if(action){
        return (
                <StyledTag color='purple'>Required</StyledTag>
        )
    }

    return <></>
}
