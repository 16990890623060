import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {editorImpersonateUser} from "../../redux-actions/editor";
import BasicLayout from "../../views/NoMenuLayout";
import styled from 'styled-components'
import {Icon} from "antd";

const Wrapper = styled.div`
  display: grid;
  height: 600px;
  justify-content: center;
  align-content: center;

  grid-template-columns: 230px;
  grid-template-rows: 140px;
`;

const Panel = styled.div`
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  background: #f7f7f7;
  padding: 20px 60px 20px 40px;
  z-index: 10;
  `

export const AdminImpersonateUser = (props) => {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if(id) {
            dispatch(editorImpersonateUser(id));
        }
    }, [id, dispatch])

    useEffect(() => {
        if(user.id === id){
            setTimeout(() => {
                history.push('/profile');
            }, 2000)
        }

    }, [user])

    return (<BasicLayout>
        <Wrapper>
            <Panel>
                <h1>Impersonating...</h1>
                <div style={{width: 50, margin: '0 auto 0 auto'}}>
                    <Icon type="loading" style={{fontSize: 50}}/>
                </div>
            </Panel>
        </Wrapper>
    </BasicLayout>)
}
