import {Tag, Tooltip} from "antd";
import React from "react";
import styled from "styled-components"

const StyledTag = styled(Tag)`
  width: 100px;
  text-align: center;
  color: #fd0006;
    background: #fff1f0;
    border-color: #fe7276;
`

export const OverdueTag = ({overdue, date}) => {
    if(overdue){
        return (
        <Tooltip overlayStyle={{minWidth: 255}} title={`Waiting for a review since: ${date.substring(0,10)}`}>
            <StyledTag>Overdue</StyledTag>
        </Tooltip>
        )
    }

    return <></>
}
