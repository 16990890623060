import React, {Component} from 'react';
import {Button, Card, Icon, Tag, Timeline, Tooltip} from "antd";
import _ from "lodash-es";
import {conference} from "../constants/strings";
import {If} from "babel-plugin-jsx-control-statements/macro"
import config from "../config";
import download from 'downloadjs'

const getStatusColor = (status) => conference.abstractStatusColor[status] || "rgba(0, 0, 0, 0.5)";

const getCommentIcon = (comment) => {
    const statusColor = getStatusColor(comment.status);
    const iconStyle = {fontSize: '16px', color: statusColor};

    if (comment.status === 'UNDER_REVIEW') return <Icon type='left-circle' style={iconStyle}/>;
    return <Icon type='right-circle' style={iconStyle}/>
};

class PaperReviewView extends Component {

    render() {
        const {review, showPublishedStatus} = this.props;
        const comments = _.get(review, 'comments', []);
        let timeline = comments.map((item) => {
            return <Timeline.Item
                key={item.id}
                dot={getCommentIcon(item)}
            >
                <Tag color={getStatusColor(item.status)}>
                    {_.get(item, 'createdAt', '').substring(0, 10)}
                </Tag>
                <If condition={item.status}>
                    <Tooltip placement="top" title={conference.abstractStatusTip[item.status]}>
                        <Tag style={{width: 150, textAlign: "center"}} color={getStatusColor(item.status)}>
                            {conference.abstractStatus[item.status]}
                        </Tag>
                    </Tooltip>
                </If>
                <If condition={showPublishedStatus}>
                    <Tag>
                        {item.published ?
                            <Tooltip placement="top" title="Published (visible to author)">
                                <Icon type="eye" />
                            </Tooltip>
                             :
                            <Tooltip placement="top" title="Unpublished (visible only to editors)">
                                <Icon type="eye-invisible" />
                            </Tooltip>
                        }
                    </Tag>
                </If>
                <p style={{marginBottom: 0, whiteSpace: 'pre-wrap'}}>{_.get(item, 'comment')}</p>
                <If condition={item.file}>
                    <p style={{marginBottom: 0}}>
                        <Button onClick={() => download(`${config.apiUrl}/papers/reviewer-comments/${item.id}/file`)}
                                style={{padding: 0}}
                                size="small"
                                type="link"
                                icon="cloud-download">
                            Attached file
                        </Button>
                    </p>
                </If>
            </Timeline.Item>
        });


        const title = review?.reviewer?.id ? `Review (${review.reviewer.firstName} ${review.reviewer.lastName})` : 'Review';
        return (
            <div>
                <Card title={title} style={{...this.props.style}}>
                    {/*<Spin spinning={_.isEmpty(review)}>*/}
                    <Timeline>
                        {timeline}
                    </Timeline>
                    {/*</Spin>*/}
                </Card>
            </div>
        );
    }
}

export default PaperReviewView;
