import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Form, Icon, Input, Row, Select} from 'antd';
import {withRouter} from "react-router-dom";
import config from "../../config";
import Author from "./Author";
import _ from "lodash-es";
import {If} from "babel-plugin-jsx-control-statements/macro";
import {PaperUpload} from "./PaperUpload";
import notAllowAllCapital from "../../validators/notAllowAllCapital";
import {CopyrightUpload} from "./CopyrightsUpload";

const {paperTemplate, copyrightsForm} = config;
const FormItem = Form.Item;

let tmpAuthorId = 1;

const Comment = ({form, hint, title, fieldName}) => {
    const {getFieldDecorator} = form;

    return (
        <Row>
        <Col>
            <FormItem
                label={title}
            >
                {getFieldDecorator(fieldName, {})(
                    <Input.TextArea placeholder={hint} rows={3}/>
                )}
            </FormItem>
        </Col>
    </Row>)
}

const PaperSubmission = ({form, paper, keywords, keywordsSearch, type, onSubmit, submitEnabled}) => {

    const {getFieldDecorator, getFieldValue} = form;
    const [showKeywords, setShowKeywords] = useState(false);


    useEffect(() => {
        if(paper.authors && paper.authors.length > 0) {
            form.setFieldsValue({
                // authors: abstract.authors,
                keys: type === 'submit' ? [0] : Array.from(paper.authors.keys()),
                paperFile: type === 'submit' ? [] : [{
                    uid: paper.paperFile,
                    name: `Paper_${paper.authors[0].lastName}_${paper.authors[0].firstName}`,
                    url: `${config.apiUrl}/papers/${paper.id}/paper`,
                    status: 'done'
                }],
                copyrightFile: type === 'submit' ? [] : [{
                    uid: paper.copyrightFile,
                    name: `Copyright`,
                    url: `${config.apiUrl}/papers/${paper.id}/copyright`,
                    status: 'done'
                }]
            });
        }
        // eslint-disable-next-line
    }, [paper]);



    const InfoMessage = () => {
        return (
            <div>
                <ul>
                    <li>An accepted abstract is required to submit a full paper.</li>
                    <li>Please use <a
                        href={paperTemplate}>this template</a> to prepare your paper.
                    </li>
                    <If condition={config.copyrightsForm}>
                        <li><a
                            href={copyrightsForm}>Copyrights form</a> needs to be signed by all authors.
                        </li>
                    </If>
                </ul>
            </div>
        )
    };

    const handleKeywordsSelect = (value, option) => {
        setShowKeywords(false);
    };

    const handleKeywordsSearch = (value) => {
        value = value.trim();        // prevents spaces before
        if (value.length <= 2) {
            setShowKeywords(false);
            return
        }

        keywordsSearch(value);
        setShowKeywords(true)
    };


    const addAuthor = (e) => {
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(tmpAuthorId++);

            form.setFieldsValue({
                keys: nextKeys,
                // speaker
            });
    };

    const removeAuthor = (index) => {
        let values = form.getFieldsValue();


        if (values.keys.length === 1) {
            return;
        }

        const authors = values.authors.filter((v, i) => i !== index);
        const keys = values.keys.filter((v, i) => i !== index);
        form.setFieldsValue({authors, keys});
    };

    const moveDownAuthor = (index) => {
        let {authors} = form.getFieldsValue(['authors']);
        [authors[index], authors[index+1]] = [authors[index+1], authors[index]];
        form.setFieldsValue({authors});
    };

    const moveUpAuthor = (index) => {
        let {authors} = form.getFieldsValue(['authors']);
        [authors[index], authors[index-1]] = [authors[index-1], authors[index]];
        form.setFieldsValue({authors});
    };

    const handleSubmit = (e) => {
        onSubmit(e)
    }

    const getTitleCaption = () => {
        switch(type) {
            case 'submit': return "Submit full paper";
            case 'edit':
            case 'resubmit': return `Edit: '${_.truncate(paper.title, {length: 150, omission: '...'}) || ''}'`;
            default: return '';
        }
    };

    const getSubmitButtonCaption = () => {
        switch(type) {
            case 'submit': return 'Submit';
            case 'edit': return 'Save';
            case 'resubmit': return 'Submit';
            default: return 'Save';
        }
    };

    getFieldDecorator('keys', {initialValue: [0]});
    const keys = getFieldValue('keys');


    return (
        <Card title={getTitleCaption()} style={{marginBottom: 20}}>
            {type === 'submit' && <Row>
                <Col xs={24}>
                    <Alert
                      style={{marginBottom: 20}}
                      showIcon
                      closable
                      message="Paper submission rules & guidelines"
                      description={<InfoMessage/>}
                      type="info"
                    />

                </Col>
            </Row>
            }

            <Form onSubmit={handleSubmit} layout='vertical'>
                <p className="ant-form-item-required">Authors:</p>
                {

                    paper.authors && keys.map((k, index) => <Author
                                            form={form}
                                            key={k}
                                            author={paper.authors[index]}
                                            index={index}
                                            count={keys.length}
                                            onRemove={() => removeAuthor(index)}
                                            onMoveDown={() => moveDownAuthor(index)}
                                            onMoveUp={() => moveUpAuthor(index)}
                                        />)
                }

                <Row gutter={25}>
                    <Col xs={3}>
                        <FormItem>
                            <Button type="primary" onClick={addAuthor}>
                                <Icon type="plus"/> Add author
                            </Button>
                        </FormItem>
                    </Col>
                </Row>


                <Row>
                    <Col xs={24}>
                        <FormItem
                            label="Title"
                        >
                            {getFieldDecorator('title', {
                                rules: [{
                                    required: true, message: 'Title is required',
                                },
                                {
                                    validator: notAllowAllCapital
                                }
                                ],

                                initialValue: _.get(paper, `title`, '')
                            })(
                                <Input/>
                            )}
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={25}>

                    <Col lg={24} xs={24}>
                        <FormItem
                            label="Keywords"
                        >
                            {getFieldDecorator('keywords', {
                                rules: [{
                                    required: true, message: 'Keywords are required',
                                }],
                                initialValue: _.get(paper, `keywords`, [])
                            })(
                                <Select
                                    mode="tags"
                                    notFoundContent={null}
                                    tokenSeparators={[',']}
                                    placeholder="Comma separated list of keywords (at least 5)"
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    open={showKeywords}
                                    onSearch={handleKeywordsSearch}
                                    onSelect={handleKeywordsSelect}
                                    dropdownMatchSelectWidth={false}
                                >
                                    {keywords.map((keyword, i) => <Select.Option key={`${keyword}_${i}`}
                                                                                       value={keyword}>{keyword}</Select.Option>)}
                                </Select>,
                            )}
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col xl={4} xs={24}>
                        <PaperUpload form={form} abstract={paper}/>
                    </Col>
                    <If condition={config.copyrightsForm}>
                        <Col xl={4} xs={24}>
                             <CopyrightUpload required={config.copyrightsForm} form={form} abstract={paper}/>
                        </Col>
                    </If>
                </Row>

                <If condition={type === 'resubmit'}>
                    <Comment
                        form={form}
                        fieldName="editor-answer"
                        title={'Message for the editor'}
                        hint={`Message for the editor...`}
                    />
                    {paper?.reviews?.map((review, num) => <Comment
                        key={review.id}
                        form={form}
                        title={`Reply to the the reviewer ${num+1}`}
                        fieldName={`reviewer-answer[${num}]`}
                        hint={`Reply to the the reviewer ${num + 1}...`}
                    />)}
                </If>
                <FormItem >
                    <Button type="primary" disabled={!submitEnabled} htmlType="submit" icon={submitEnabled ? "right" : "loading"}>{getSubmitButtonCaption()}</Button>
                </FormItem>
            </Form>
        </Card>
    );
};

export default withRouter(Form.create()(PaperSubmission));

