import React from "react";
import {Form, Select} from 'antd';
import styled from 'styled-components'

const { Option } = Select;
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;
`


const RoleForm = ({form, initialData}) => {
    const {getFieldDecorator} = form;
    return (
            <Form layout="horizontal">
                        <StyledFormItem label="Role" {...layout}>
                            {getFieldDecorator('role', {
                                initialValue: initialData.role,
                            })(
                                <Select >
                                    <Option value="author">Author</Option>
                                    <Option value="reviewer">Reviewer</Option>
                                    <Option value="editor">Editor</Option>
                                    <Option value="admin">Administrator</Option>
                                </Select>,
                            )}
                        </StyledFormItem>
            </Form>

    )
};

export default Form.create()(RoleForm)
