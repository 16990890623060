import React, {useRef, useState} from 'react';
import {Button, Icon, Input, Tag} from 'antd';
import {Link} from "react-router-dom";
import {conference} from "../constants/strings";
import _ from "lodash-es";
import download from "downloadjs";
import cd from 'content-disposition'
import {Scrollbar} from "react-scrollbars-custom";
import {NoPaddingCard} from "./Components/NoPaddingCard";
import {StyledTable} from "./Components/StyledTable";
import styles from "./AdminAbstracts.module.scss"
import {Paid} from "./Components/Paid";
import {useQueryState} from "react-router-use-location-state";

const AbstractTitle = ({id, setLastId, title}) => {
    return (
        <Link to={`/editor/abstracts/${id}`} onClick={() => setLastId(id)}>{title}</Link>
    )
}

const AdminAbstracts = ({ abstracts, sessions, downloadAllAbstracts, setLastId, lastId}) => {
    // const [dataLoading, setDataLoading] = useState(true);
    const [disabledDwnBtn, setDisabledDwnBtn] = useState(false);
    const searchInputRef = useRef(null);

    const handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    const handleReset = clearFilters => () => {
        clearFilters();
    };

    const renderSearchBox = (dialogTitle) => {
        return ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div className={styles.searchBox}>
                <Input
                    ref={searchInputRef}
                    placeholder={dialogTitle}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={handleSearch(selectedKeys, confirm)}
                />
                <Button type="primary" onClick={handleSearch(selectedKeys, confirm)}>Search</Button>
                <Button onClick={handleReset(clearFilters)}>Reset</Button>
            </div>
        )
    };

    const renderAuthors = (authors) => {
        return (
                <ul className={styles.authorList}>
                    {authors.map(author => {
                        if(author.refId)
                            return (<li key={author._id}><Link to={`/editor/users/${author.refId}`}>{author.firstName} {author.lastName}</Link></li>);
                        return (<li key={author._id}>{author.firstName} {author.lastName}</li>)
                        }
                    )}
                </ul>
            )
    };

    const [page, setPage] = useQueryState('page', 1);
    const [pageSize, setPageSize] = useQueryState('pageSize', 500);

    const pagination = {
        defaultPageSize: pageSize,
        defaultCurrent: page,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['15', '50', '100', '200', '500'],
        onChange: (page) => setPage(page),
        onShowSizeChange: (current, size) => setPageSize(size),
    };

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            // render: (value, record, index) => index + 1,
            // width: 55,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            //key: 'title',
            filterDropdown: renderSearchBox("Search by title"),
            onFilter: (value, record) => record.title.toLowerCase().includes(value.toLowerCase()),
            render: (title, record) => <AbstractTitle id={record._id} setLastId={setLastId} title={record.title}/>,
        },
        {
            title: 'Authors',
            dataIndex: 'authors',
            render: (authors, record) => renderAuthors(authors),
            //key: 'author',
            width: '15%',
            filterDropdown: renderSearchBox("Search by author"),
            onFilter: (value, record) => record.authors.filter(author => author.lastName.toLowerCase() === value.toLowerCase() ||
                author.firstName.toLowerCase() === value.toLowerCase()).length > 0
        },
        {
            title: 'Paid',
            //key: 'paid',
            width: 120,
            render: (value, record, index) => <Paid value={record.paid}/>
        },
        {
            title: 'Session',
            dataIndex: 'session',
            width: '15%',
            render: (value, record) => _.get(sessions.find(s => s.acronym === value), 'title', ''),
            //key: 'session',
            sorter: (a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <span>
                    <Tag color={conference.abstractStatusColor[status]}
                         key={status}>{conference.abstractStatus[status]}</Tag>
                </span>
            ),
            width: 140,
            sorter: (a, b) => a.status.localeCompare(b.status),
        }, {
            title: 'Created at',
            //key: 'submissionDate',
            dataIndex: 'createdAt',
            width: 120,
            defaultSortOrder: 'descend',
            render: date => date.substring(0, 10),
            sorter: (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt),
        }, {
            title: 'Last update',
            //key: 'updateDate',
            dataIndex: 'updatedAt',
            width: 120,
            render: date => date.substring(0, 10),
            sorter: (a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt),
        },

        ];


    const handleDownloadAll = () => {
        setDisabledDwnBtn(true);
        downloadAllAbstracts()
            .then(response => {
                const disposition = cd.parse(response.payload.headers['content-disposition']);
                setDisabledDwnBtn(false);
                return download(response.payload.data, disposition.parameters.filename);
            })
    };

    // const rowRendered = (record, index) => {
    //     if(index === (abstracts.length - 1)){
    //         setDataLoading(false);
    //     }
    // };

    const rowClassName = (record, index) => {
        return record._id === lastId ? styles.visitedRow : null;
    }

    return (
            <div>
                <NoPaddingCard extra={
                    <Button shape="round" disabled={disabledDwnBtn} onClick={handleDownloadAll}>
                        <Icon type="download" spin={disabledDwnBtn}/>
                    </Button>}
                               title='Submitted abstracts'>
                    <Scrollbar style={{height: 'calc(100vh - 235px)'}}>
                        <StyledTable rowKey="_id"
                                     // onRow={rowRendered}
                                     rowClassName={rowClassName}
                                     // loading={dataLoading}
                                     columns={columns}
                                     dataSource={abstracts}
                                     pagination={pagination}
                        />
                    </Scrollbar>
                </NoPaddingCard>
            </div>
        );

};

AdminAbstracts.whyDidYouRender = {
    // logOnDifferentValues: true,
    customName: 'AdminAbstracts'
}


export default AdminAbstracts


