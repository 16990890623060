import React, {useCallback, useEffect} from 'react';
import BasicLayout from "../../views/MainLayout";
import {
    editorClearPapers,
    editorGetPapers,
    editorDeletePaper
} from "../../redux-actions/editor";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import AdminPapers from "../../lists/AdminPapers";
import {ROLE_ADMIN} from "../../constants/strings";

const AdminPapersListPage = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const papers = useSelector(state => state.editor.papers, shallowEqual);
    const role = useSelector(state => state.auth.user.role)


    const handleDeletePaper = useCallback((paper) => dispatch(editorDeletePaper(paper.id)), [
        dispatch,
    ]);


    useEffect(() => {
        dispatch(editorGetPapers());
    }, [dispatch, location])

    useEffect(() => {
        return () => {
            dispatch(editorClearPapers());
        }
    }, [dispatch]);

    return (
        <BasicLayout>
            <AdminPapers
                papers={papers}
                isAdmin={role === ROLE_ADMIN}
                deletePaper={handleDeletePaper}
                // downloadAllAbstracts={handleDownloadAllPapers}
            />
        </BasicLayout>
    );
};

export default AdminPapersListPage;
