import React, {useEffect, useState} from "react";
import {Avatar, List, Tooltip} from "antd";
import dayjs from "dayjs";
import styled from 'styled-components'
import {Scrollbar} from "react-scrollbars-custom";
import _ from 'lodash-es'

const DateSpan = styled.span`
  margin-right: 20px;
  color: #a2a2a2;
  font-size: 0.9em;
`

const StyledMeta = styled(List.Item.Meta)`
  .ant-list-item-meta-title {
    padding-top: 5px;
    font-weight: normal;
  }
`

const ActivityAvatar = ({type}) => {
    const size = 30;
    let style = {backgroundColor: '#0065ca'}
    switch (type) {
        case 'USER_REGISTERED':
            style = {backgroundColor: '#5b8c00'}
            return <Avatar icon="user-add" size={size} style={style}/>;
        case 'ABSTRACT_SUBMITTED':
            return <Avatar icon="file-word" size={size} style={style}/>;
        case 'ABSTRACT_EDITED':
            style = {backgroundColor: '#237804'}
            return <Avatar icon="file-word" size={size} style={style}/>;
        case 'ABSTRACT_REMOVED':
        case 'ABSTRACT_REMOVED_BY_EDITOR':
            style = {backgroundColor: '#cf1322'}
            return <Avatar icon="delete" size={size} style={style}/>;
        case 'ABSTRACT_REVIEWED':
            style = {backgroundColor: '#531dab'}
            return <Avatar icon="file-done" size={size} style={style}/>;
        case 'USER_ROLE_CHANGED':
            return <Avatar icon="tag" size={size} style={style}/>;
        case 'USER_UPDATED':
            return <Avatar icon="user" size={size} style={style}/>;
        case 'USER_FEE_STATUS':
            style = {backgroundColor: '#ffec3d', color: '#000'}
            return <Avatar icon="credit-card" size={size} style={style}/>;
        case 'USER_REMOVED_BY_EDITOR':
            style = {backgroundColor: '#cf1322'}
            return <Avatar icon="user-delete" size={size} style={style}/>;
        case 'PAPER_REMOVED':
            style = {backgroundColor: '#cf1322'}
            return <Avatar icon="delete" size={size} style={style}/>;
        case 'PAPER_SUBMITTED':
            style = {backgroundColor: '#237804'}
            return <Avatar icon="file-word" size={size} style={style}/>;
        case 'PAPER_RESUBMITTED':
            style = {backgroundColor: '#237804'}
            return <Avatar icon="file-sync" size={size} style={style}/>;
        case 'PAPER_REVIEWED':
            style = {backgroundColor: '#ad00ab'}
            return <Avatar icon="file-done" size={size} style={style}/>;
        case 'PAPER_REMOVED_BY_EDITOR':
            style = {backgroundColor: '#cf1322'}
            return <Avatar icon="file-word" size={size} style={style}/>;
        default:
            return <Avatar icon="question-circle" size={size} style={style}/>;
    }
};

const ActivityLog = ({getActivities}) => {
    const [activities, setActivities] = useState([])
    useEffect(() => {
        getActivities().then((response) => {
            setActivities(response.payload.data)
        });
    }, [getActivities]);

    return (
        <Scrollbar style={{height: 'calc(100vh - 235px)'}}>
            <List
                itemLayout="horizontal"
                dataSource={activities}
                renderItem={item => (
                    <List.Item
                        key={item._id}
                        extra={
                            <DateSpan>{dayjs(item.time).format('YYYY-MM-DD HH:mm:ss')}</DateSpan>
                        }
                    >
                        <StyledMeta
                            avatar={<ActivityAvatar type={item.type}/>}
                            title={<Tooltip title={item.msg}>
                                {_.truncate(item.msg, {length: 100, omission: '...'})}
                            </Tooltip>}
                        />
                    </List.Item>
                )}
            />
        </Scrollbar>
    )
};


export default ActivityLog
