import React, {useEffect, useRef, useState} from 'react';
import BasicLayout from "../../views/MainLayout";
import PaperSubmissionForm from "../../forms/paper/PaperSubmission";
import {bindActionCreators} from "redux";
import {
    addFirstAuthorToPaper,
    clearPaper,
    getPaperById,
    resubmitPaper,
    searchKeywords,
    submitPaper,
    updatePaper
} from "../../redux-actions/author";
import connect from "react-redux/es/connect/connect";
import {useHistory, useParams, withRouter} from "react-router-dom";
import {message} from "antd";

const PaperSubmissionPage = ({type, paper, addFirstAuthor, clearPaper, getPaper, updatePaper, submitPaper, resubmitPaper, keywords, keywordsSearch, auth}) => {
    let formRef = useRef(null);
    let history = useHistory();
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const {id} = useParams();


    useEffect(() => {
        return () => {
            if (history.action === "POP")
                history.replace(history.location.pathname);
        }
    }, [history]);

    useEffect(() => {
        if (id) getPaper(id)
    }, [id]);     // only initial render!

    useEffect(() => {
        if(type === 'submit') {
            setFirstAuthor();
        }
    }, [type]);

    useEffect(() => {
        return () => {
            clearPaper();
        }
    }, []);


    const setFirstAuthor = () => {
        const {firstName, lastName, email, affiliation} = auth.user;
        addFirstAuthor(firstName, lastName, email, affiliation)
    };


//    let action;

    const handleSubmit = (e) => {
        e.preventDefault();

         formRef.current.getForm().validateFields((err, values) => {
            if (err) return;
            const {paperFile, copyrightFile, ...meta} = values;
            delete meta['keys'];

            setSubmitEnabled(false);

            if(type === 'submit') {
                submitPaper(paperFile[0].originFileObj, copyrightFile?.[0].originFileObj, meta).then((res => {
                    history.push('/papers');
                })).catch(exception => {
                    const msg = exception.error.response?.data?.error;
                    if(msg)
                        message.error(msg);
                    else
                        message.error("The paper could not be sent due to errors.");

                    setSubmitEnabled(true);
                });
            } else if(type === 'edit') {
                updatePaper(paperFile[0].originFileObj, copyrightFile?.[0].originFileObj, meta, paper.id).then((res => {
                    history.goBack();
                })).catch(exception => {
                    message.error("The paper could not be updated due to errors.");
                    setSubmitEnabled(true);
                });
            } else if(type === 'resubmit') {
                resubmitPaper(paperFile[0].originFileObj, copyrightFile?.[0].originFileObj, meta, paper.id).then((res => {
                    history.push('/papers');
                })).catch(exception => {
                    message.error("The paper could not be updated due to errors.");
                    setSubmitEnabled(true);
                });
            }
        });
    };




    return (
        <BasicLayout>
            <PaperSubmissionForm
                type={type}
                paper={paper}
                onSubmit={handleSubmit}
                submitEnabled={submitEnabled}
                keywords={Array.from(new Set(keywords))}
                keywordsSearch={keywordsSearch}
                wrappedComponentRef={formRef}
            />
        </BasicLayout>
    );
};


const mapStateToProps = (state, ownProps) => ({
    paper: state.author.paper,
    auth: state.auth,
    keywords: state.author.keywords,
    sessions: state.settings.sessions
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getPaper: getPaperById,
        updatePaper: updatePaper,
        submitPaper: submitPaper,
        clearPaper: clearPaper,
        addFirstAuthor: addFirstAuthorToPaper,
        resubmitPaper: resubmitPaper,
        keywordsSearch: searchKeywords
    },
    dispatch,
);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaperSubmissionPage))
