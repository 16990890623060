import React from 'react';
import BasicLayout from "../../views/MainLayout";
import PaperReviews from "../../lists/PaperReviews";

function PaperReviewsList(props) {
    return (
        <BasicLayout>
            <PaperReviews/>
        </BasicLayout>
    );
}

export default PaperReviewsList;
