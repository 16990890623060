import React, {useEffect, useState} from "react"
import {ComposableMap, Geographies, Geography, ZoomableGroup,} from "react-simple-maps"
import {Col, Row} from "antd";
import _ from 'lodash-es'
import config from '../../../config'
import {MapLegendBar, palette} from "./MapLegendBar";
import {withRouter} from "react-router-dom";

function UserMapPanel(props) {
    const [toolTip, setToolTip] = useState('');


    if (_.isEmpty(props.data))
        return <></>;


    const handleMapClick = (geo, evt) => {
        props.history.push(`/editor/users?country=${geo.properties['ISO_A2']}`)
    }

    const stats = props.data;
    const max = Math.max(...Object.values(stats));
    const localPalette = palette(max)

    const fillByProperty = (iso) => {
        if (_.isUndefined(stats[iso])) return "#ffffff"
        const numOfRegisteredUsers = stats[iso]
        return localPalette(numOfRegisteredUsers)
    }

    return (
      <>
        <Row justify="center" type="flex" >
            <Col xs={24} xl={18} xxl={16}>
                        <ComposableMap
                            data-tip="" data-for='map'
                            style={{ width: "100%", height: "100%"}}>
                            <ZoomableGroup center={[0, 20]} zoom={1}>
                                <Geographies geography={`${config.apiUrl}/geo/map`}>
                                    {(geographies, projection) => geographies.map((geography, i) => (
                                        <Geography
                                            key={geography.properties.ISO_A2 + i}
                                            cacheId={geography.properties.ISO_A2 + i}
                                            geography={geography}
                                            projection={projection}
                                            onClick={handleMapClick}
                                            onMouseEnter={() => {
                                                const { NAME, ISO_A2 } = geography.properties;
                                                setToolTip(`${NAME} - ${(stats[ISO_A2] ?? 0)}`);
                                            }}
                                            // onMouseLeave={() => {
                                            //     setToolTip("");
                                            // }}
                                            style={{
                                                default: {
                                                    fill: fillByProperty(geography.properties.ISO_A2),
                                                    stroke: "#607D8B",
                                                    strokeWidth: 0.75,
                                                    outline: "none",
                                                },
                                                hover: {
                                                    fill: fillByProperty(geography.properties.ISO_A2),
                                                    stroke: "#607D8B",
                                                    strokeWidth: 2.0,
                                                    outline: "none",
                                                },
                                                pressed: {
                                                    fill: fillByProperty(geography.properties.ISO_A2),
                                                    stroke: "#607D8B",
                                                    strokeWidth: 2.0,
                                                    outline: "none",
                                                }
                                            }}
                                        />

                                    ))}
                                </Geographies>
                            </ZoomableGroup>
                        </ComposableMap>

                    </Col>
        </Row>
          <Row>
            <Col xs={24}>
                <div style={{height: '1em', textAlign: 'center'}}>{toolTip}</div>
            </Col>
          </Row>
          <Row type="flex" justify="center" style={{marginTop: 50}}>
              <Col xs={16} lg={6}>
                  <MapLegendBar max={max}/>
              </Col>
          </Row>
       </>
    )
}

export default withRouter(UserMapPanel);
