import React, {useCallback, useEffect, useRef, useState} from 'react';
import BasicLayout from "../../views/MainLayout";
import {
    editorAddPaperComment,
    editorAssignReviewersToPaper,
    editorDeletePaper,
    editorEditPaperComment,
    editorGetPaperById,
    editorGetReviewers,
    editorPublishEditorialComment,
} from "../../redux-actions/editor";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from 'react-router-dom'
import {Button, Col, message, Modal, Popconfirm, Row, Tooltip} from "antd";
import PaperView from "../../fragments/PaperView";
import AssignReviewersForm from "../../forms/AssignReviewers";
import PaperReviewView from "../../fragments/PaperReviewView";
import PaperEditorCommentsView from "../../fragments/PaperEditorCommentsView";
import PaperEditorForm from "../../forms/PaperEditor";
import {ROLE_ADMIN} from "../../constants/strings";
const { confirm } = Modal;

const ExtraButtons = ({editAction, deleteAction, assignAction}) => {
    return (
        <>
            <Tooltip placement="topLeft" title="Assign reviewers">
                <Button style={{margin: 5}} shape="circle" icon="solution" onClick={assignAction}/>
            </Tooltip>
            <Tooltip placement="topLeft" title="Edit">
                <Button style={{margin: 5}} shape="circle" icon="edit" onClick={editAction}/>
            </Tooltip>
            <Popconfirm
                title="Are you sure to delete this paper?"
                onConfirm={deleteAction}
                okText="Yes"
                cancelText="No">
                <Button style={{margin: 5}} shape="circle" icon="delete"/>
            </Popconfirm>

        </>
    )
};

const AdminPaperPage = (props) => {
    let history = useHistory();
    const { id } = useParams();
    let reviewerFormRef = useRef(null);
    let editorFormRef = useRef(null);
    const dispatch = useDispatch();

    const paper = useSelector(state => state.editor.paper);
    const reviewers = useSelector(state => state.editor.reviewers);

    const [assignVisible, setAssignVisible] = useState(false);
    const [editorFormVisible, setEditorFormVisible] = useState(false);
    const [editorFormContent, setEditorFormContent] = useState({});
    const role = useSelector(state => state.auth.user.role)


    const addEditorialComment = useCallback((paper, comment) => dispatch(editorAddPaperComment(paper, comment)), [
        dispatch,
    ]);

    const editEditorialComment = useCallback((paper, comment) => dispatch(editorEditPaperComment(paper, comment)), [
        dispatch,
    ]);

    const publishEditorialComment = useCallback((paper, comment) => dispatch(editorPublishEditorialComment(paper, comment)), [
        dispatch,
    ]);

    const getReviewers = useCallback(() => dispatch(editorGetReviewers()), [dispatch]);
    const assignReviewers = useCallback((paper, reviewers) => dispatch(editorAssignReviewersToPaper(paper, reviewers)), [dispatch]);
    const deletePaper = useCallback((paperId) => dispatch(editorDeletePaper(paperId)), [dispatch]);
    const getPaperById = useCallback((paperId) => dispatch(editorGetPaperById(paperId)), [dispatch]);

    useEffect(() => {
        getReviewers();
    }, [getReviewers]);

    useEffect(() => {
        getPaperById(id)
    }, [getPaperById, id]);


    const cardStyle = {marginTop: 20};

    const handleAssignReviewer = () => {
        reviewerFormRef.current.getForm().validateFields((err, values) => {
            if (err) return;
            assignReviewers(paper, values.reviewer).then((response) => {
                message.success('Reviewers have been assigned');
            }).catch((error) => {
                message.error('Reviewers have not been assigned due to error');
            });
            setAssignVisible(false);
        })
    };

    const handleOpenEditorForm = () => {
        setEditorFormVisible(true);
    };

    const handleCloseEditorForm = () => {
        setEditorFormVisible(false);
        setEditorFormContent({});
    };

    const handleSubmitEditorForm = () => {
        const addSuccessMsg =  'Editorial comment has been added.';
        const addFailedMsg = 'Editorial comment has NOT been added due to error';
        const editSuccessMsg = 'Editorial comment has been updated.';
        const editFailedMsg = 'Editorial comment has NOT been updated due to error';
        if(editorFormContent.id) {
            return editorFormRef.current.getForm().validateFields((err, values) => {
                if (err) return;
                editEditorialComment(paper, {...values, id: editorFormContent.id}).then((response) => {
                    message.success(editSuccessMsg);
                }).catch((error) => {
                    message.error(editFailedMsg);
                });

                setEditorFormVisible(false);
                setEditorFormContent({});
            })
        }

        editorFormRef.current.getForm().validateFields((err, values) => {
            if (err) return;
            addEditorialComment(paper, values).then((response) => {
                message.success(addSuccessMsg);
            }).catch((error) => {
                message.error(addFailedMsg);
            });
            setEditorFormVisible(false);
            setEditorFormContent({});
        })

    };

    const handleEditorCommentEditClick = (comment) => {
        // click on [EDIT] next to comment
        setEditorFormVisible(true);
        setEditorFormContent(comment);
    };

    const handleEditorPublishClick = (comment) => {
        // click on [PUBLISH]

        const publish = () => {
            publishEditorialComment(paper, comment).then((response) => {
                message.success('Comment has been published.');
            }).catch((error) => {
                message.error('Comment has NOT been published due to error');
            });
        };

        confirm({
            title: 'Continue?',
            content: <span>This action will publish this comment. <ul>
                <li>This cannot be undone,</li>
                <li>Paper status will change,</li>
                <li>All reviewers comments will be visible to the participant,</li>
                <li>Notification mail will be sent to the participant.</li>
                </ul>
                <strong>Are you sure you want to continue?</strong>
            </span>,
            onOk: publish,
            onCancel: () => {}
        });
    };

    return (
        <BasicLayout>
            <Row>
                <Col xs={24}>
                    <PaperView
                        paper={paper}
                        allowEdit={true}
                        isAdmin={role === ROLE_ADMIN}
                        extraButtons={<ExtraButtons
                            editAction={() => history.push(`/editor/papers/${paper.id}/edit`)}
                            deleteAction={() => {
                                deletePaper(paper.id);
                                history.push(`/editor/papers/`);
                            }}
                            assignAction={() => setAssignVisible(true)}
                        />}
                    />

                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PaperEditorCommentsView
                        source={paper}
                        style={cardStyle}
                        data={paper?.editor}
                        onEditClick={handleEditorCommentEditClick}
                        onPublishClick={handleEditorPublishClick}
                        actions={[
                            <Tooltip key='tooltip-actions' placement="topLeft" title="Add new comment">
                                <Button style={{margin: 5}} shape="circle" icon="notification"
                                        onClick={handleOpenEditorForm}/>
                            </Tooltip>
                        ]}/>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    {paper?.reviews?.map(rev => <PaperReviewView
                        key={rev.id}
                        showPublishedStatus={true}
                        style={cardStyle}
                        review={rev}/>)
                    }
                </Col>
            </Row>


            <Modal
                title={`Assign reviewer`}
                visible={assignVisible}
                onOk={handleAssignReviewer}
                onCancel={() => setAssignVisible(false)}
            >
                <AssignReviewersForm ref={reviewerFormRef} source={paper} reviewers={reviewers}/>
            </Modal>

            <Modal
                title={`Editor comment`}
                visible={editorFormVisible}
                onOk={handleSubmitEditorForm}
                onCancel={handleCloseEditorForm}
            >
                <PaperEditorForm ref={editorFormRef} data={editorFormContent} parentPaper={paper} />
            </Modal>


        </BasicLayout>
    )

};


AdminPaperPage.whyDidYouRender = {
    customName: 'AdminPaperPage'
};

export default AdminPaperPage
